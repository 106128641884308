import React, { useState } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import language from "../../constants/languages/en/translations.json";
import "./InstanceMaintenance.scss";

const ChangeStatusModal = ({
  onClose,
  setInstanceName,
  handleStatusUpdate,
  currentStatus,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(currentStatus); // State to track selected status
  const [message, setMessage] = useState(""); // State to track message

  const handleStatusChange = (event) => {
    setSelectedStatus(event); // Update selected status state
  };

  const handleUpdateClick = () => {
    handleStatusUpdate(selectedStatus, message);
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{language.ChangeStatusEN}</h2>
          <MotifButton onClick={onClose} className="close-button" size="large">
            &times;
          </MotifButton>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group">
              <MotifLabel>{language.InstanceNameEN}</MotifLabel>
              <MotifFormField>
                <MotifInput
                  className="session-modal-input session-name-input"
                  value={setInstanceName}
                  aria-describedby="session-name"
                  hideClearButton={true}
                  disabled={true}
                />
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifLabel id="select-label">{language.StatusEN}</MotifLabel>
              <MotifFormField>
                <MotifSelect
                  className="change-status-modal"
                  onChange={handleStatusChange}
                  value={selectedStatus} // Set default value
                >
                  <MotifOption value={language.StatusActiveEN}>
                    {language.StatusActiveEN}
                  </MotifOption>
                  <MotifOption value={language.StatusInactiveEN}>
                    {language.StatusInactiveEN}
                  </MotifOption>
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>

          <div className="inactive-message-box">
            <TextField
              placeholder={language.MessageToTheUser}
              multiline
              fullWidth
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              disabled={selectedStatus === language.StatusActiveEN}
              sx={{
                backgroundColor:
                  selectedStatus === language.StatusActiveEN
                    ? "#f0f0f0"
                    : "inherit",
              }}
            />
            <MotifLabel id="select-label">
              {language.InactiveMessageEN}
            </MotifLabel>
          </div>
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {language.InstanceMaintainenceCancelEN}
          </MotifButton>
          <MotifButton
            onClick={handleUpdateClick}
            className="button done-button"
          >
            {language.InstanceMaintainenceDoneEN}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

ChangeStatusModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setInstanceName: PropTypes.string.isRequired,
  handleStatusUpdate: PropTypes.func.isRequired,
  currentStatus: PropTypes.string,
};

export default ChangeStatusModal;
