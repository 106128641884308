import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_GL_URL,
  CREATE_GL_URL,
  EDIT_GL_URL,
  DELETE_GL_URL,
} from "../../services/Api";

export const fetchAllGl = createAsyncThunk(
  "getAllGlData",
  async (moduleId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_GL_URL}/${moduleId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching projects:", error);
      return rejectWithValue(error.response?.data || "Error fetching projects");
    }
  }
);
export const createGl = createAsyncThunk(
  "createGl",
  async ({ moduleId, glData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${CREATE_GL_URL}/${moduleId}`, glData);
      return response.data;
    } catch (error) {
      console.error("Error creating Data:", error);
      return rejectWithValue(error.response?.data || "Error creating Data");
    }
  }
);
export const updateGl = createAsyncThunk(
  "updateGl",
  async ({ glId, glData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${EDIT_GL_URL}/${glId}`, glData);
      return response.data;
    } catch (error) {
      console.error("Error updating Data:", error);
      return rejectWithValue(error.response?.data || "Error updating Data");
    }
  }
);

export const deleteGl = createAsyncThunk(
  "deleteGl",
  async ({ glId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${DELETE_GL_URL}/${glId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting Data:", error);
      return rejectWithValue(error.response?.data || "Error deleting Data");
    }
  }
);

const fetchAllGlSlice = createSlice({
  name: "allGldata",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGl.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchAllGl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAllGl.rejected, (state, action) => {
        console.error("error", action.payload);
        state.isError = true;
      })
      .addCase(createGl.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createGl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createGl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(updateGl.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(updateGl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateGl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(deleteGl.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteGl.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteGl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      });
  },
});

export default fetchAllGlSlice.reducer;
