import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createNewSustainabilityMatters,
  deleteSustainabilityMatters,
  fetchEsrs,
  fetchEsrsAr16,
  fetchParents,
  getAllSustainabilityMatters,
  updateSustainabilityMatters,
} from "../../../../features/slices/SustainabilityMatters";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import TreeView from "../../../../components/treeView/TreeView";
import SustainabilityMattersModal from "../../../../components/modals/dmaModals/SustainabilityMattersModal";
import languageEN from "../../../../constants/languages/en/translations.json";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import "./SustainabillityMatters.scss";

const SustainabilityMatters = () => {
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    mode: "add",
  });

  const [options, setOptions] = useState({
    parent: [],
    esrs: [],
    esrsar16: [],
  });

  const [filteredUpdateData, setFilteredUpdateData] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateItemID, setUpdateItemID] = useState(null);
  const [deleteItemID, setDeleteItemID] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const { moduleId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(getAllSustainabilityMatters(moduleId)),
      dispatch(fetchEsrs()),
      dispatch(fetchEsrsAr16()),
      dispatch(fetchParents(moduleId)),
    ])
      .then(([smResponse, esrsResponse, esrsar16Response, parentsResponse]) => {
        if (smResponse?.payload?.data) {
          setSmData(smResponse.payload.data);
        }
        if (esrsResponse?.payload?.data) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            esrs: esrsResponse.payload.data.map((type) => ({
              value: type.id,
              label: type.name,
            })),
          }));
        }
        if (esrsar16Response?.payload?.data) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            esrsar16: esrsar16Response.payload.data.map((type) => ({
              value: type.id,
              label: type.name,
            })),
          }));
        }
        if (parentsResponse?.payload?.data) {
          setOptions((prevOptions) => ({
            ...prevOptions,
            parent: parentsResponse.payload.data.map((type) => ({
              value: type.id,
              label: type.name,
            })),
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [dispatch, moduleId, updateTrigger]);

  const sustainabilityMattersState = useSelector(
    (state) => state.dmaSustainabilityMatters
  );

  const { isLoading } = sustainabilityMattersState;

  const [smData, setSmData] = useState([]);

  const handleOpenModal = (mode, itemId) => {
    setModal({
      isOpen: true,
      mode: mode,
      title: mode === "add" ? languageEN.newSustainabilityMatters : modal.title,
    });
  };

  const handleCloseModal = () => {
    setModal({
      ...modal,
      isOpen: false,
    });
  };

  const findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) {
        return item;
      } else if (item.children) {
        const result = findItemById(item.children, id);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const handleEditClick = (id) => {
    handleOpenModal("edit", id);
    const filteredData = findItemById(smData, id);
    setFilteredUpdateData(filteredData);
    setUpdateItemID(id);
  };

  const handleDeleteClick = (id) => {
    setIsDeleteModalOpen(true);
    setDeleteItemID(id);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteSustainabilityMatters({ sustainabilityMattersId: deleteItemID })
    )
      .unwrap()
      .then(() => {
        handleDeleteCloseModal();
        setUpdateTrigger((prev) => prev + 1);
      })
      .catch((error) => {
        console.error("Deletion failed:", error);
        alert("Failed to delete sustainability matter: " + error);
      });
  };

  const createNewSustainabilityMattersApi = async (
    SustainabilityMattersData
  ) => {
    if (SustainabilityMattersData) {
      try {
        await dispatch(
          createNewSustainabilityMatters({
            moduleId,
            SustainabilityMattersData,
          })
        ).unwrap();
        setUpdateTrigger((prev) => prev + 1);
        handleCloseModal();
      } catch (error) {
        console.error("Creation failed:", error);
        alert("Failed to create sustainability matters: " + error);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateSustainabilityMattersApi = async (SustainabilityMattersData) => {
    if (SustainabilityMattersData) {
      try {
        const response = await dispatch(
          updateSustainabilityMatters({
            sustainabilityMattersId: updateItemID,
            SustainabilityMattersData,
          })
        ).unwrap();
        if (response.success) {
          handleCloseModal();
          setUpdateTrigger((prev) => prev + 1);
        }
      } catch (error) {
        console.error("Update failed:", error);
        alert("Failed to update sustainability matters: " + error);
      }
    } else {
      alert("Please fill in all required fields");
    }
  };
  const transformedItems =
    smData?.map((item) => {
      return {
        id: item.id,
        itemId: item.id,
        label: item.label || item.name,
        description: item.description,
        children: item.children || [],
      };
    }) || [];
 
  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      <div>
        <ContextSelection
          addButtonText="Add new"
          pageName="SM"
          downloadButtonText="Download longlist"
          onClickNewProject={() => handleOpenModal("add")}
        />
      </div>
      <div className="sm-file-explorer">
        <TreeView
          items={transformedItems}
          defaultExpandedItems={[]}
          defaultSelectedItems="1"
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
      </div>
      {modal.isOpen && (
        <SustainabilityMattersModal
          onClose={handleCloseModal}
          modalTitle={modal.title}
          modalMode={modal.mode}
          topicNameLabel={languageEN.TopicName}
          parentLabel={languageEN.Parent}
          esrsLabel={languageEN.ESRS}
          esrsAr16Label={languageEN.ESRS_AR16}
          esgLabel={languageEN.ESG}
          enterTopicNameLabel={languageEN.EnterTopicName}
          selectParentLabel={languageEN.SelectParent}
          selectEsrsLabel={languageEN.SelectESRS}
          selectEsrsAr16Label={languageEN.SelectESRS}
          DescriptionLabel={languageEN.Description}
          saveButtonLabel={languageEN.addSustainabilityMatters}
          updateButtonLabel={languageEN.Update}
          cancelButtonLabel={languageEN.Cancel}
          esrsOptions={options.esrs}
          esrsar16Options={options.esrsar16}
          parentOptions={options.parent}
          handleDoneClick={
            modal.mode === "add"
              ? createNewSustainabilityMattersApi
              : updateSustainabilityMattersApi
          }
          filteredUpdateData={filteredUpdateData}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          setName={languageEN.DeleteLabel}
          labelText={languageEN.DeletConfirmationSources}
          onCancel={() => setIsDeleteModalOpen(false)}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={languageEN.Cancel}
          deleteButtonLabel={languageEN.DeleteLabel}
        />
      )}
    </>
  );
};

export default SustainabilityMatters;
