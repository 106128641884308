import React from "react";
import "./ContextSelection.scss";
import { MotifButton } from "@ey-xd/motif-react";
import { Add, Download } from "../../constants/icons/Icons";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

const ContextSelection = ({
  onChangeSearchInput,
  addButtonText,
  onClickNewProject,
  downloadButtonText,
  onClickNewDownload,
  pageName,
}) => {
  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value || "";
    onChangeSearchInput(inputValue.toLowerCase());
  };

  return (
    <div id="context-selection">
      <Paper component="form" className="search-box">
        <IconButton type="button" className="search-icon" aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          className="search-box-input"
          placeholder="Search"
          onChange={handleSearchInputChange}
          inputProps={{ "aria-label": "search For" }}
        />
      </Paper>

      <div className="context-selection-button-container">
        {pageName === "SM" && (
          <MotifButton
            className="context-selection-button-download"
            onClick={onClickNewDownload}
            size="small"
            type="submit"
            variant="primary-alt"
          >
            {downloadButtonText}
            <span className="icon">
              <Download />
            </span>
          </MotifButton>
        )}

        <MotifButton
          className="context-selection-button-add"
          onClick={onClickNewProject}
          size="small"
          type="submit"
          variant="primary-alt"
        >
          {addButtonText}
          <span className="icon">
            <Add />
          </span>
        </MotifButton>
      </div>
    </div>
  );
};

ContextSelection.propTypes = {
  onChangeSearchInput: PropTypes.func.isRequired,
  addButtonText: PropTypes.string.isRequired,
  onClickNewProject: PropTypes.func.isRequired,
  onClickNewDownload: PropTypes.func,
  downloadButtonText: PropTypes.string,
  pageName: PropTypes.string,
};

export default ContextSelection;
