import React from "react";
import ContextSubHeader from "../../../components/contextSubHeader/ContextSubHeader";
import ContextStep from "../../../components/contextStep/ContextStep";
import Thresholds from "./thresholds/Thresholds";

const DmaProcessSpecification = () => {
  return (
    <div>
      <ContextSubHeader>
        <ContextStep key="thresholds" text="Thresholds">
          <Thresholds />
        </ContextStep>
        <ContextStep key="scale" text="Scale"></ContextStep>
        <ContextStep key="scope" text="Scope"></ContextStep>
        <ContextStep key="remediability" text="Remediability"></ContextStep>
        <ContextStep key="likelihood" text="Likelihood"></ContextStep>
        <ContextStep key="severity-risk" text="Severity Risk"></ContextStep>
        <ContextStep
          key="severity-opportunities"
          text="Severity opportunities"
        ></ContextStep>
        <ContextStep key="timehorizon" text="Timehorizon"></ContextStep>
      </ContextSubHeader>
    </div>
  );
};

export default DmaProcessSpecification;
