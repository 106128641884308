import React, { useEffect, useState } from "react";
import {
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import Slider from "@mui/material/Slider";

import "./ThresholdCard.scss";

const ThresholdCard = ({
  type,
  sliderHeader,
  sliderDescription,
  thresholdToInput,
  updateThreshold,
}) => {
  const [thresholdRange, setThresholdRange] = useState(thresholdToInput);
  const [threshold, setThreshold] = useState(0);

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 5,
      label: "5",
    },
  ];

  const handleChange = (e) => {
    if (parseFloat(e.target.value) > 5.0 || parseFloat(e.target.value) < 0.0) {
      alert("value cant be more than 5");
    } else if (e.target.value.length > 3) {
      alert("only one decimal value is allowed");
    } else {
      setThresholdRange(e.target.value);
    }
  };

  useEffect(() => {
    setThreshold(thresholdRange);
  }, [thresholdRange]);

  const handleUpdateThreshold = () => {
    setThreshold(thresholdRange);

    if (threshold) {
      const data = {
        threshold: parseFloat(threshold),
        type: type,
      };
      updateThreshold(data);
    }
  };

  return (
    <MotifCard className="threshold-card">
      <MotifCardHeader
        style={{ fontFamily: "EYInterstate" }}
        variant="alt"
        className="threshold-header"
      >
        {sliderHeader}
      </MotifCardHeader>
      <MotifCardBody>
        <div className="slider">
          <input
            type="number"
            value={thresholdRange}
            onChange={handleChange}
            onBlur={handleUpdateThreshold}
            className="threshold-input"
            data-testid="textbox"
            style={{ fontFamily: "EYInterstate" }}
            min={1}
            max={5}
            step={0.1}
          />

          <div className="slider-component">
            <Slider
              color="#E6E6E9"
              value={thresholdRange}
              step={0.1}
              min={0}
              max={5}
              onChange={handleChange}
              marks={marks}
              onChangeCommitted={handleUpdateThreshold}
              data-testid="slider"
            />
          </div>
        </div>

        <p className="description" style={{ fontFamily: "EYInterstate" }}>
          {sliderDescription}
        </p>
      </MotifCardBody>
    </MotifCard>
  );
};

ThresholdCard.propTypes = {
  type: PropTypes.string,
  sliderHeader: PropTypes.string,
  sliderDescription: PropTypes.string,
  thresholdToInput: PropTypes.number,
  updateThreshold: PropTypes.func,
};

export default ThresholdCard;
