import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifMessage,
} from "@ey-xd/motif-react";
import Slider from "@mui/material/Slider";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./StakeHolder.scss";

const StakeHolder = ({
  onClose,
  setName,
  handleDoneClick,
  modalMode,
  NameLabel,
  TypeLabel,
  IELabel,
  InfluenceLabel,
  PowerLabel,
  DescriptionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  selectedRow,
  typeOptions,
  ieOptions,
}) => {
  const isEditMode = modalMode === "edit";

  const [nameValue, setNameValue] = useState("");
  const [type, setType] = useState(0);
  const [ie, setIe] = useState(0);
  const [influence, setInfluence] = useState(0);
  const [power, setPower] = useState(0);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (selectedRow && isEditMode) {
      setNameValue(selectedRow.name || "");
      setType(selectedRow.stakeHolderType.id || "");
      setIe(selectedRow.stakeHolderIntExtType.id || "");
      setInfluence(parseFloat(selectedRow.influence) || 0);
      setPower(parseFloat(selectedRow.power) || 0);
      setDescription(selectedRow.description || "");
    }
  }, [selectedRow, isEditMode]);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!nameValue.trim()) newErrors.name = "Name is required";
    if (!type) newErrors.type = "Type is required";
    if (!ie) newErrors.ie = "I/E is required";
    if (influence < 0 || influence > 10)
      newErrors.influence = "Influence must be between 0 and 10";
    if (power < 0 || power > 10)
      newErrors.power = "Power must be between 0 and 10";
    if (!description.trim()) newErrors.description = "Description is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validateForm()) {
      const data = {
        name: nameValue,
        typeId: type,
        intExtTypeId: ie,
        influence: influence,
        power: power,
        description: description,
      };
      handleDoneClick(data);
    }
  };

  const isFormValid = () => {
    return (
      nameValue.trim() !== "" &&
      type > 0 &&
      ie > 0 &&
      influence >= 0 &&
      influence <= 10 &&
      power >= 0 &&
      power <= 10 &&
      description.trim() !== ""
    );
  };

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group full-width">
              <MotifLabel>{NameLabel}</MotifLabel>
              <MotifFormField>
                <MotifInput
                  className="session-modal-input session-name-input"
                  aria-describedby="session-name"
                  hideClearButton={true}
                  value={nameValue}
                  onChange={(e) => setNameValue(e.target.value)}
                  error={!!errors.name}
                />
                {errors.name && (
                  <span className="error-text">{errors.name}</span>
                )}
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <MotifMessage id="session-framework">{TypeLabel}</MotifMessage>
              <MotifFormField>
                <MotifLabel id="select-type-label">{"Select Type"}</MotifLabel>
                <MotifSelect
                  className=""
                  onChange={(e) => setType(e)}
                  value={type}
                  ariaLabelledBy="select-type-label"
                  error={!!errors.type}
                >
                  {typeOptions.map((type) => (
                    <MotifOption key={type.value} value={type.value}>
                      {type.label}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {errors.type && (
                  <span className="error-text">{errors.type}</span>
                )}
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifMessage id="session-framework">{IELabel}</MotifMessage>
              <MotifFormField>
                <MotifLabel id="select-label">{"Select I/E"}</MotifLabel>
                <MotifSelect
                  className=""
                  onChange={(e) => setIe(e)}
                  value={ie}
                  error={!!errors.ie}
                >
                  {ieOptions.map((type) => (
                    <MotifOption key={type.value} value={type.value}>
                      {type.label}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {errors.ie && <span className="error-text">{errors.ie}</span>}
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <MotifLabel id="select-label">{InfluenceLabel}</MotifLabel>
              <MotifFormField>
                <Slider
                  color="#E6E6E9"
                  value={influence}
                  min={0}
                  max={10}
                  onChange={(e) => setInfluence(parseFloat(e.target.value))}
                  data-testid="slider"
                  valueLabelDisplay="auto"
                  marks={marks}
                />
                {errors.influence && (
                  <span className="error-text">{errors.influence}</span>
                )}
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifLabel id="select-label">{PowerLabel}</MotifLabel>
              <MotifFormField>
                <Slider
                  color="#E6E6E9"
                  value={power}
                  min={0}
                  max={10}
                  onChange={(e) => setPower(parseFloat(e.target.value))}
                  data-testid="slider"
                  valueLabelDisplay="auto"
                  marks={marks}
                />
                {errors.power && (
                  <span className="error-text">{errors.power}</span>
                )}
              </MotifFormField>
            </div>
          </div>
          <MotifLabel id="select-label">{DescriptionLabel}</MotifLabel>
          <TextField
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={!!errors.description}
            helperText={errors.description}
          />
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {cancelButtonLabel}
          </MotifButton>
          <MotifButton
            onClick={handleSave}
            className="button done-button"
            disabled={!isFormValid()}
          >
            {isEditMode ? updateButtonLabel : saveButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

StakeHolder.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  handleDoneClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  TypeLabel: PropTypes.string.isRequired,
  IELabel: PropTypes.string.isRequired,
  InfluenceLabel: PropTypes.string.isRequired,
  PowerLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  selectedRow: PropTypes.object,
  typeOptions: PropTypes.array,
  ieOptions: PropTypes.array,
};

export default StakeHolder;