import { configureStore } from "@reduxjs/toolkit";
import projectDetailsReducer from "./slices/ProjectScreens";
import fetchAllProjectsReducer from "./slices/InstanceHome";
import fetchAllUsersReducer from "./slices/UsersData";
import getAllStatusSliceReducer from "./slices/InstanceMaintenance";
import fetchAllGlSliceReducer from "./slices/DMAGeographicalLocation";
import dmaOverviewReducer from "./slices/DMAOverview";
import dmaProcessSpecificationReducer from "./slices/DMAProcessSpecification";
import dmaValueChainReducer from "./slices/DMAValueChain";
import dmaApsSliceReducer from "./slices/DMAAps";
import dmaSourcesSliceReducer from "./slices/DMASources"
import dmaStakeHolderReducer from "./slices/DMAStakeHolder"
import dmaSustainabilityMattersReducer from "./slices/SustainabilityMatters";

export const store = configureStore({
  reducer: {
    projectDetails: projectDetailsReducer,
    allProjects: fetchAllProjectsReducer,
    allUsers: fetchAllUsersReducer,
    allStatus: getAllStatusSliceReducer,
    allGldata: fetchAllGlSliceReducer,
    dmaProcessSpecification: dmaProcessSpecificationReducer,
    dmaOverview: dmaOverviewReducer,
    dmaValueChain: dmaValueChainReducer,
    dmaAps: dmaApsSliceReducer,
    dmaStakeHolder : dmaStakeHolderReducer,
    dmaSources: dmaSourcesSliceReducer,
    dmaSustainabilityMatters:dmaSustainabilityMattersReducer
  },
});
