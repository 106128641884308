import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NewProject from "../pages/newProject/NewProject";
import ProjectHome from "../pages/projectHome/ProjectHome";
import ClientOrg from "../pages/clientOrganisation/ClientOrg";
import UserManagement from "../pages/userManagement/UserManagement";
import InstanceHome from "../pages/instanceHome/InstanceHome";
import EditProject from "../pages/newProject/EditProject";
import InstanceMaintenance from "../pages/instanceMaintenance/InstanceMaintenance";
import DMA from "../pages/dma/DMA";


const AppRoutes = () => {
  return (
    <Router>
      <Routes>
      <Route exact path="/" element={<InstanceHome/>}/>
      <Route exact path="/maintenance" element={<InstanceMaintenance/>}/>
        <Route exact path="/create-new-project" element={<NewProject />}/>
        <Route exact path = "/project-home/:projectId" element={<ProjectHome/>}/>
        <Route exact path="/client-org/:projectId" element = {<ClientOrg/>}/>
        <Route exact path="/user-management/:projectId" element = {<UserManagement/>}/>
        <Route exact path="/edit-project/:projectId" element = {<EditProject/>}/>
        <Route exact path="/dma/:moduleId" element = {<DMA/>}/>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
