import React from "react";
import "./ProjectDetails.scss";
import {
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react";
import { MotifIcon } from "@ey-xd/motif-react";
import ProjectDetailTable from "./ProjectDetailTable";
import PropTypes from "prop-types";

function ProjectDetails({
  onClose,
  onOpen,
  EditEN,
  ProjectDetailTitleEN,
  projectName,
  clientName,
  country,
  engagementcode,
  projectSizeUSD,
  clientSector,
  projectAdministrator,
  data,
  handleEditNavigate,
}) {
  return (
    <div>
      <div className="project-details-accordion">
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          <MotifAccordion
            onClose={onClose}
            onOpen={onOpen}
            style={{
              flex: "1",
            }}
            variant="editorial"
          >
            <MotifAccordionTrigger>
              <div className="trigger-content">
                {ProjectDetailTitleEN}
                <div className="edit">
                  <a
                    className="edit-link"
                    onClick={handleEditNavigate}
                    href={handleEditNavigate}
                  >
                    {" "}
                    {EditEN}{" "}
                  </a>
                  &nbsp;
                  <MotifIcon
                    className="motifIcon"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNC4zNjMyIDUuNjUxNTZMMTUuODQzMSA0LjE3MTU3QzE2LjYyNDIgMy4zOTA1MiAxNy44OTA1IDMuMzkwNTIgMTguNjcxNiA0LjE3MTU3TDIwLjA4NTggNS41ODU3OUMyMC44NjY4IDYuMzY2ODMgMjAuODY2OCA3LjYzMzE2IDIwLjA4NTggOC40MTQyMUwxOC42MDU4IDkuODk0Mk0xNC4zNjMyIDUuNjUxNTZMNC43NDc0OSAxNS4yNjcyQzQuNDE1NDIgMTUuNTk5MyA0LjIxMDc5IDE2LjAzNzYgNC4xNjk0NyAxNi41MDU0TDMuOTI3MzggMTkuMjQ1OUMzLjg3MjYxIDE5Ljg2NTkgNC4zOTE0OCAyMC4zODQ4IDUuMDExNSAyMC4zM0w3Ljc1MTkxIDIwLjA4NzlDOC4yMTk3MiAyMC4wNDY2IDguNjU4MDYgMTkuODQxOSA4Ljk5MDEzIDE5LjUwOTlMMTguNjA1OCA5Ljg5NDJNMTQuMzYzMiA1LjY1MTU2TDE4LjYwNTggOS44OTQyIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                    title="Icon Button"
                  />
                  &nbsp;&nbsp;
                </div>
              </div>
            </MotifAccordionTrigger>
            <MotifAccordionContent className="project-details-table">
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div className="project-details-table-admin">
                <ProjectDetailTable
                  data={data}
                  projectName={projectName}
                  clientName={clientName}
                  country={country}
                  engagementcode={engagementcode}
                  projectSizeUSD={projectSizeUSD}
                  clientSector={clientSector}
                  projectAdministrator={projectAdministrator}
                />
              </div>
              &nbsp; &nbsp;
            </MotifAccordionContent>
          </MotifAccordion>
        </div>
      </div>
    </div>
  );
}

ProjectDetails.propTypes = {
  onClose: PropTypes.bool,
  onOpen: PropTypes.bool,
  EditEN: PropTypes.string,
  ProjectDetailTitleEN: PropTypes.string,
  projectName: PropTypes.string,
  clientName: PropTypes.string,
  country: PropTypes.string,
  engagementcode: PropTypes.string,
  projectSizeUSD: PropTypes.string,
  clientSector: PropTypes.string,
  projectAdministrator: PropTypes.string,
  data: PropTypes.object,
  handleEditNavigate: PropTypes.func,
};

export default ProjectDetails;
