import React, { useState, useEffect } from "react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import languageEN from "../../../../constants/languages/en/translations.json";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit, Delete } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import StakeHolder from "../../../../components/modals/dmaModals/StakeHolder";
import {
  fetchStakeHolder,
  fetchStakeHolderIETypes,
  fetchStakeHolderTypes,
  createNewStakeHolder,
  updateStakeHolder,
  deleteStakeHolder,
} from "../../../../features/slices/DMAStakeHolder";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TopBar from "../Topbar";
import Pagination from "../../../../components/paginationForCards/Pagination";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./Stakeholders.scss";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";

const Stakeholders = () => {
  const [isShTableView, setIsShTableView] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [modalTitle, setModalTitle] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchShQuery, setSearchShQuery] = useState("");
  const [stakeData, setStakeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [typeOptions, setTypeOptions] = useState([]);
  const [ieOptions, setIeOptions] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const itemsPerPage = 10;
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.dmaStakeHolder);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [stakeholderResponse, typesResponse, ieTypesResponse] =
          await Promise.all([
            dispatch(fetchStakeHolder(moduleId)).unwrap(),
            dispatch(fetchStakeHolderTypes()).unwrap(),
            dispatch(fetchStakeHolderIETypes()).unwrap(),
          ]);

        if (typesResponse.success) {
          setTypeOptions(
            typesResponse.data.map((type) => ({
              value: type.id,
              label: type.name,
            }))
          );
        }

        if (ieTypesResponse.success) {
          setIeOptions(
            ieTypesResponse.data.map((ieType) => ({
              value: ieType.id,
              label: ieType.name,
            }))
          );
        }

        if (Array.isArray(stakeholderResponse.data)) {
          const flattenedData = stakeholderResponse.data.map((item) => ({
            ...item,
            typeName: item.stakeHolderType?.name || "Unknown",
            ie: item.stakeHolderIntExtType?.name || "Unknown",
          }));
          setStakeData(flattenedData);
        } else {
          setStakeData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, moduleId, updateTrigger]);

  const filteredData = [
    { value: "filter1", label: "Filter 1" },
    { value: "filter2", label: "Filter 2" },
  ];

  const sortedData = [
    { value: "sort1", label: "Sort 1" },
    { value: "sort2", label: "Sort 2" },
  ];

  const handleEdit = (row) => {
    setSelectedRow(row);
    setModalMode("edit");
    setModalTitle(languageEN.EditStakeHolder);
    setIsModalOpen(true);
  };

  const handleDelete = () => {
    dispatch(deleteStakeHolder({ StakeHolderId: selectedRow.id }))
      .unwrap()
      .then(() => {
        handleDeleteCloseModal();
        setUpdateTrigger((prev) => prev + 1);
      })
      .catch((error) => {
        console.error("deletion failed:", error);
        alert("Failed to delete stakeholder: " + error);
      });
  };

  const handleDeleteOpenModal = (row) => {
    setSelectedRow(row);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const renderShTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell key="actions" style={{ textAlign: "right" }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDeleteOpenModal(row)}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    if (colName === "description") {
      return (
        <TableCell key={index} className="truncate">
          {value}
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const createNewStakeHolderApi = async (StakeHolderData) => {
    if (StakeHolderData) {
      dispatch(createNewStakeHolder({ moduleId, StakeHolderData }))
        .unwrap()
        .then((response) => {
          if (response.success) {
            setUpdateTrigger((prev) => prev + 1);
            handleCloseModal();
          }
        })
        .catch((error) => {
          console.error("Creation failed:", error);
          alert("Failed to create stakeholder: " + error);
        });
    } else {
      alert("Please fill in all required fields");
    }
  };

  const updateStakeHolderApi = async (StakeHolderData) => {
    if (StakeHolderData) {
      dispatch(
        updateStakeHolder({ StakeHolderId: selectedRow.id, StakeHolderData })
      )
        .unwrap()
        .then((response) => {
          if (response.success) {
            setUpdateTrigger((prev) => prev + 1);
            handleCloseModal();
          }
        })
        .catch((error) => {
          console.error("Update failed:", error);
          alert("Failed to update stakeholder: " + error);
        });
    } else {
      alert("Please fill in all required fields");
    }
  };

  const generateRowKey = (row) => row.id;

  const handleOpenModal = (mode) => {
    setModalMode(mode);
    setSelectedRow(null);
    if (mode === "add") {
      setModalTitle(languageEN.NewStakeHolder);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    setSearchShQuery(String(event));
  };

  const getGlFilteredData = () => {
    return Array.isArray(stakeData)
      ? stakeData.filter((item) =>
          item.name?.toLowerCase().includes(searchShQuery.toLowerCase())
        )
      : [];
  };

  const paginatedData = getGlFilteredData().slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const columns = [
    { colName: "name", label: "Name", showSorting: true },
    { colName: "ie", label: "I/E", showSorting: true },
    { colName: "influence", label: "Influence", showSorting: true },
    { colName: "power", label: "Power", showSorting: true },
    { colName: "typeName", label: "Type", showSorting: true },
    { colName: "description", label: "Description", showSorting: true },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  const totalPages = Math.ceil(getGlFilteredData().length / itemsPerPage);

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleOpenModal("add")}
        SelectFilter={languageEN.FilterBy}
        SelectSort={languageEN.SortBy}
        OptionFilter="Filter Option"
        filteredData={filteredData}
        sortedData={sortedData}
        OptionSort="Sort Option"
        onChangeSearchInput={handleSearch}
      />
      <TopBar
        isTableView={isShTableView}
        totalResults={stakeData.length}
        filteredResults={getGlFilteredData().length}
        onTableViewClick={() => setIsShTableView(true)}
        onCardViewClick={() => setIsShTableView(false)}
      />
      <div className="sh-card-table-switch">
        {isShTableView ? (
          <ClientTable
            columns={columns}
            data={getGlFilteredData()}
            itemsPerPage={5}
            renderTableCell={renderShTableCell}
            generateRowKey={generateRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                descriptionHeaderText={item.description}
                descriptionBodyText={item.ie}
                pageName={"StakeHolder"}
                influence={item.influence}
                power={item.power}
                stakeHolderType={item.typeName}
                handleEdit={() => handleEdit(item)}
                handleDelete={() => handleDeleteOpenModal(item)}
              />
            ))}
          </div>
        )}
      </div>
      {!isShTableView && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
      {isModalOpen && (
        <StakeHolder
          onClose={handleCloseModal}
          setName={modalTitle}
          handleStatusChange={() => {}}
          handleDoneClick={
            modalMode === "add" ? createNewStakeHolderApi : updateStakeHolderApi
          }
          modalMode={modalMode}
          NameLabel={languageEN.Name}
          TypeLabel={languageEN.Type}
          IELabel={languageEN.IELabel}
          InfluenceLabel={languageEN.InfluenceLabel}
          PowerLabel={languageEN.PowerLabel}
          DescriptionLabel={languageEN.Description}
          cancelButtonLabel={languageEN.Cancel}
          saveButtonLabel={languageEN.AddToList}
          updateButtonLabel={languageEN.Update}
          selectedRow={selectedRow}
          typeOptions={typeOptions}
          ieOptions={ieOptions}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          setName={languageEN.DeleteLabel}
          labelText={languageEN.DeletConfirmationSources}
          onCancel={() => setIsDeleteModalOpen(false)}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={languageEN.Cancel}
          deleteButtonLabel={languageEN.DeleteLabel}
        />
      )}
    </div>
  );
};

export default Stakeholders;