import React, { useState, useEffect, useRef, useMemo, Suspense } from "react";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { TableCell, Box, IconButton } from "@mui/material";
import { Edit, Delete, View } from "../../constants/icons/Icons";
import language from "../../constants/languages/en/translations.json";
import Header from "../../components/headers/Header";
import Subheader from "../../components/subheader/Subheader";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProjects } from "../../features/slices/InstanceHome";
import { useNavigate } from "react-router-dom";

const ClientTable = React.lazy(() =>
  import("../../components/clientTable/ClientTable")
);

const InstanceHome = ({ showHeader = true, showButton = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectIDsRef = useRef([]);
  const isAdmin = true;
  const [searchQuery, setSearchQuery] = useState("");
  const [transformedData, setTransformedData] = useState([]);

  useEffect(() => {
    dispatch(fetchAllProjects());
  }, [dispatch]);

  const { data: responseData, isLoading } = useSelector(
    (state) => state.allProjects || { data: null, isLoading: false }
  );

  const columnsToDisplay = useMemo(
    () => [
      {
        colName: "name",
        label: language.ProjectNameEN,
        showSorting: true,
      },
      { colName: "Action", label: "Action", showSorting: false },
    ],
    []
  );

  useEffect(() => {
    if (responseData?.data) {
      console.log("Raw response data:", responseData.data);
      projectIDsRef.current = responseData.data.map((item) => item.id);
      const data = responseData.data.map((item, index) => {
        const transformedItem = {};
        columnsToDisplay.forEach((column) => {
          if (column.colName !== "Action") {
            transformedItem[column.colName] = item[column.colName];
          }
        });
        transformedItem.id = projectIDsRef.current[index];
        return transformedItem;
      });
      setTransformedData(data);
    }
  }, [responseData, columnsToDisplay]);

  const handleNavigate = () => {
    navigate("/create-new-project");
  };

  const generateRowKey = (row) => row.id;

  const getCellStyle = (column, index) => {
    return column === "Action"
      ? { textAlign: "right" }
      : { textAlign: index === 0 ? "left" : "right" };
  };

  const renderTableCell = (column, value, row, index) => {
    const cellStyle = getCellStyle(column, index);
    const cellPaddingRight = index === column?.length - 1 ? "3%" : "0";
    if (column === "Action") {
      return (
        <TableCell key="Action" style={cellStyle}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={() => handleButtonClick(row.id, "View")}
              data-testid="view-button"
            >
              <View />
            </IconButton>
            <IconButton onClick={() => handleButtonClick(row.id, "Edit")}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleButtonClick(row.id, "Delete")}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    } else {
      return (
        <TableCell
          key={column}
          style={{ ...cellStyle, paddingRight: cellPaddingRight }}
        >
          {value}
        </TableCell>
      );
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleButtonClick = (projectId, buttonName) => {
    switch (buttonName) {
      case "View":
        navigate(`/project-home/${projectId}`);
        break;
      case "Edit":
        navigate(`/edit-project/${projectId}`);
        break;
      case "Delete":
        // Handle delete action (optional)
        break;
      default:
        navigate(`/`);
        break;
    }
  };

  const filteredData = transformedData.filter((item) => {
    const nameValue = item[ "name"] || ""; // Default to an empty string if undefined
    const query = searchQuery.toLowerCase();
    return nameValue.toLowerCase().includes(query);
  });

  return (
    <div className="all-client-container">
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      {showHeader && <Header />}
      <div className="client-org-container">
        <div className="client-org-subheader">
          <Subheader
            isAdmin={isAdmin}
            page={language.AddNewAccessTitleProjectEN}
            title={language.InstanceHomeProjectsEN}
            handleNavigate={handleNavigate}
            AddNewEN={language.AddNewProjectEN}
            onChangeSearchInput={handleSearch}
            AddNewAccessTitleProjectEN={language.AddNewAccessTitleProjectEN}
            showAddNewButton={showButton}
            data-testid="add-new-project-button"
          />
        </div>
        <div>
          <Suspense
            fallback={
              <MotifProgressLoader
                data-testid="loading-spinner"
                show
                variant="default"
              />
            }
          >
            {filteredData.length > 0 ? (
              <ClientTable
                columns={columnsToDisplay}
                data={filteredData}
                itemsPerPage={5}
                section={language.InstanceHomeProjectsEN}
                generateRowKey={generateRowKey}
                getCellStyle={getCellStyle}
                renderTableCell={renderTableCell}
                handleButtonClick={handleButtonClick}
              />
            ) : (
             ""
            )}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default InstanceHome;
