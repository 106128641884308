import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const SourceValue = ({
  onClose,
  setName,
  handleSourceValueClick,
  modalMode,
  NameLabel,
  TypeLabel,
  DescriptionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  types,
  selectedRow,
  pageName,
}) => {
  const [nameInput, setNameInput] = useState("");
  const [typeId, setTypeId] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (modalMode === "edit" && selectedRow) {
      setNameInput(selectedRow.name);
      switch (pageName) {
        case "Sources":
          setTypeId(selectedRow.sourceType.id);
          break;
        case "Value Chain":
          setTypeId(selectedRow.valueChainType.id);
          break;
        case "APS":
          setTypeId(selectedRow.actProServiceType.id);
          break;
        default:
          break;
      }
      setDescription(selectedRow.description);
    }
  }, [modalMode, selectedRow, pageName]);

  const isEditMode = modalMode === "edit";

  const handleSaveClick = () => {
    const sourcesData = {
      name: nameInput,
      description,
      typeId,
    };
    handleSourceValueClick(sourcesData);
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group">
              <MotifLabel htmlFor="name-input">{NameLabel}</MotifLabel>
              <MotifFormField>
                <MotifInput
                  id="name-input"
                  data-testid="name-input" 
                  className="session-modal-input session-name-input"
                  aria-describedby="session-name"
                  hideClearButton={true}
                  value={nameInput}
                  onChange={(e) => setNameInput(e.target.value)}
                />
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifLabel htmlFor="type-select">{TypeLabel}</MotifLabel>
              <MotifFormField>
                <MotifSelect
                  id="type-select"
                  className="change-status-modal"
                  onChange={(e) => setTypeId(e)}
                  value={typeId}
                >
                  {types?.map((type) => (
                    <MotifOption key={type.id} value={type.id}>
                      {type.name}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>
          <MotifLabel htmlFor="description-input">
            {DescriptionLabel}
          </MotifLabel>
          <TextField
            id="description-input"
            data-testid="description-input" 
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {cancelButtonLabel}
          </MotifButton>
          <MotifButton onClick={handleSaveClick} className="button done-button">
            {isEditMode ? updateButtonLabel : saveButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

SourceValue.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  handleSourceValueClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  TypeLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedRow: PropTypes.object,
};

export default SourceValue;
