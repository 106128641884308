import React from "react";
import "./Subheader.scss";
import { Add } from "../../constants/icons/Icons";
import PropTypes from "prop-types";
import { MotifButton, MotifIcon } from "@ey-xd/motif-react";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
const Subheader = ({
  isAdmin,
  page,
  title,
  handleOpenModal,
  handleNavigate,
  handleSessionModal,
  handleClientOrg,
  handleUserManagement,
  onChangeSearchInput,
  ProjectHomeAccessTitleEN,
  ClientOrgTitleEN,
  UserManagementTitleEN,
  NewSessionTitleEN,
  AddNewEN,
  ClientOrgAccessTitleEN,
  AddNewAccessTitleProjectEN,
  CreateNewEN,
  showAddNewButton = true
}) => {
  return (
    <div>
      <div id="subheader">
        <h1 className="Text">{title}</h1>
        <div className="subheader-button-container">
          <div className="subheader-search">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 200,
              }}
            >
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search "
                onChange={onChangeSearchInput}
                inputProps={{ "aria-label": "search For" }}
              />
            </Paper>
          </div>
          &nbsp; &nbsp;
          {isAdmin && page === ProjectHomeAccessTitleEN && (
            <div className="subheader-buttons">
              <div className="subheader-icon-buttons">
                <MotifButton
                  className="subheader-button"
                  onClick={handleClientOrg}
                  size="small"
                  type="submit"
                  variant="text"
                >
                  {ClientOrgTitleEN}
                  &nbsp;
                  <MotifIcon
                    className="subheader-icon"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHN0cm9rZS13aWR0aD0iMS41IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDIwVjE5QzEgMTUuMTM0IDQuMTM0MDEgMTIgOCAxMlYxMkMxMS44NjYgMTIgMTUgMTUuMTM0IDE1IDE5VjIwIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMyAxNFYxNEMxMyAxMS4yMzg2IDE1LjIzODYgOSAxOCA5VjlDMjAuNzYxNCA5IDIzIDExLjIzODYgMjMgMTRWMTQuNSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNOCAxMkMxMC4yMDkxIDEyIDEyIDEwLjIwOTEgMTIgOEMxMiA1Ljc5MDg2IDEwLjIwOTEgNCA4IDRDNS43OTA4NiA0IDQgNS43OTA4NiA0IDhDNCAxMC4yMDkxIDUuNzkwODYgMTIgOCAxMloiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE4IDlDMTkuNjU2OSA5IDIxIDcuNjU2ODUgMjEgNkMyMSA0LjM0MzE1IDE5LjY1NjkgMyAxOCAzQzE2LjM0MzEgMyAxNSA0LjM0MzE1IDE1IDZDMTUgNy42NTY4NSAxNi4zNDMxIDkgMTggOVoiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="
                    title="Icon Button"
                  />
                </MotifButton>
                &nbsp; &nbsp;
                <MotifButton
                  className="subheader-button"
                  onClick={handleUserManagement}
                  size="small"
                  type="submit"
                  variant="text"
                >
                  {UserManagementTitleEN}
                  &nbsp;
                  <MotifIcon
                    className="subheader-icon"
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHN0cm9rZS13aWR0aD0iMS41IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMiAxMi41TDIxLjE2NjcgMTVNMjEuMTY2NyAxNUwyMCAxOC41SDE1LjVMMTQuNSAxNUgyMS4xNjY3WiIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTYuNSAyMC41MUwxNi41MSAyMC40OTg5IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xOS41IDIwLjUxTDE5LjUxIDIwLjQ5ODkiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTkgMTFDMTEuMjA5MSAxMSAxMyA5LjIwOTE0IDEzIDdDMTMgNC43OTA4NiAxMS4yMDkxIDMgOSAzQzYuNzkwODYgMyA1IDQuNzkwODYgNSA3QzUgOS4yMDkxNCA2Ljc5MDg2IDExIDkgMTFaIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0yIDE4QzIgMTQuMTM0IDUuMTM0MDEgMTEgOSAxMUMxMC42MzUgMTEgMTIuMTM5MSAxMS41NjA2IDEzLjMzMDYgMTIuNSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K"
                    title="Icon Button"
                  />
                </MotifButton>
              </div>
              &nbsp; &nbsp;
              <MotifButton
                className="subheader-button-add"
                onClick={handleSessionModal}
                size="small"
                type="submit"
                variant="primary-alt"
              >
                {NewSessionTitleEN}
                &nbsp;
                <Add />
              </MotifButton>
            </div>
          )}
          {isAdmin && page === ClientOrgAccessTitleEN && (
            <div className="ButtonContainer">
              <MotifButton
                className="subheader-button-add"
                onClick={handleOpenModal}
                size="small"
                type="submit"
                variant="primary-alt"
              >
                {CreateNewEN}
                &nbsp;
                <Add />
              </MotifButton>
            </div>
          )}
          {isAdmin && showAddNewButton && page === AddNewAccessTitleProjectEN && (
            <div className="ButtonContainer">
              <MotifButton
                className="subheader-button-add"
                onClick={handleNavigate}
                size="small"
                type="submit"
                variant="primary-alt"
              >
                &nbsp;
                {AddNewEN}
                <Add />
              </MotifButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Subheader.propTypes = {
  isAdmin: PropTypes.bool,
  page: PropTypes.string,
  title: PropTypes.string,
  handleOpenModal: PropTypes.func,
  handleSessionModal: PropTypes.func,
  handleClientOrg: PropTypes.func,
  handleUserManagement: PropTypes.func,
  handleNavigate: PropTypes.func,
  onChangeSearchInput: PropTypes.func,
  ProjectHomeAccessTitleEN: PropTypes.string,
  ClientOrgTitleEN: PropTypes.string,
  UserManagementTitleEN: PropTypes.string,
  NewSessionTitleEN: PropTypes.string,
  AddNewEN: PropTypes.string,
  ClientOrgAccessTitleEN: PropTypes.string,
  AddNewAccessTitleProjectEN: PropTypes.string,
  CreateNewEN: PropTypes.string,
};

export default Subheader;
