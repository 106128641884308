import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from "@mui/material";
import {
  TableView,
  CardView,
  CardViewLite,
  TableViewLite,
} from "../../../constants/icons/Icons";

const TopBar = ({
  isTableView,
  totalResults,
  filteredResults,
  onTableViewClick,
  onCardViewClick,
}) => {
  return (
    <Box padding={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" gutterBottom sx={{ paddingLeft: "5%" }}>
          Showing results {filteredResults} out of {totalResults}
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          className="gl-view-switch"
        >
          <IconButton
            data-testid="table-view-button"
            onClick={onTableViewClick}
            aria-label="Table View"
          >
            {isTableView ? <TableView /> : <TableViewLite />}
          </IconButton>
          <IconButton
            data-testid="card-view-button"
            onClick={onCardViewClick}
            aria-label="Card View"
          >
            {isTableView ? <CardViewLite /> : <CardView />}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

TopBar.propTypes = {
  isTableView: PropTypes.bool.isRequired,
  totalResults: PropTypes.number.isRequired,
  filteredResults: PropTypes.number.isRequired,
  onTableViewClick: PropTypes.func.isRequired,
  onCardViewClick: PropTypes.func.isRequired,
};

export default TopBar;
