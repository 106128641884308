import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_THRESHOLD, CREATE_UPDATE_THRESHOLD } from "../../services/Api";

export const fetchThreshold = createAsyncThunk(
  "fetchThreshold",
  async (type, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_THRESHOLD}?type=${type}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching Threshold:", error);
      return rejectWithValue(error.response?.data || "Error Threshold");
    }
  }
);

export const createUpdateThreshold = createAsyncThunk(
  "createUpdateThreshold",
  async (thresholdData, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_UPDATE_THRESHOLD, thresholdData);
      return response.data;
    } catch (error) {
      console.error("Error creating updating threshold data:", error);
      return rejectWithValue(
        error.response?.data || "Error creating updating threshold data"
      );
    }
  }
);

const dmaProcessSpecificationSlice = createSlice({
  name: "dmaProcessSpecification",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThreshold.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchThreshold.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchThreshold.rejected, (state, action) => {
      console.error("error", action.payload);
      state.isError = true;
    });
    builder.addCase(createUpdateThreshold.pending, (state) => {
      state.isLoading = true;
      state.postSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    });
    builder.addCase(createUpdateThreshold.fulfilled, (state, action) => {
      state.isLoading = false;
      state.postSuccess = true;
      state.data = action.payload;
      state.isError = false;
    });
    builder.addCase(createUpdateThreshold.rejected, (state, action) => {
      state.isLoading = false;
      state.postSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload;
    });
  },
});

export default dmaProcessSpecificationSlice.reducer;
