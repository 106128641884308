import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_APS_TYPES_URL,
  GET_ALL_APS_URL,
  CREATE_APS_URL,
  UPDATE_APS_URL,
  DELETE_APS_URL,
} from "../../services/Api";

// Fetch all APS data
export const fetchAps = createAsyncThunk(
  "dmaAps/fetchAps",
  async (moduleId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_APS_URL}/${moduleId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching APS data:", error);
      return rejectWithValue(error.response?.data || "Error fetching APS data");
    }
  }
);

// Create a new APS
export const createAps = createAsyncThunk(
  "dmaAps/createAps",
  async ({ moduleId, apsData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_APS_URL}/${moduleId}`,
        apsData
      );
      return response.data;
    } catch (error) {
      console.error("Error creating APS:", error);
      return rejectWithValue(error.response?.data || "Error creating APS");
    }
  }
);

// Update APS
export const updateAps = createAsyncThunk(
  "dmaAps/updateAps",
  async ({ apsId, apsData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${UPDATE_APS_URL}/${apsId}`, apsData);
      return response.data;
    } catch (error) {
      console.error("Error updating APS:", error);
      return rejectWithValue(error.response?.data || "Error updating APS");
    }
  }
);

// Delete APS
export const deleteAps = createAsyncThunk(
  "dmaAps/deleteAps",
  async ({ apsId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${DELETE_APS_URL}/${apsId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting APS:", error);
      return rejectWithValue(error.response?.data || "Error deleting APS");
    }
  }
);

// Fetch APS types
export const fetchApsTypes = createAsyncThunk(
  "dmaAps/fetchApsTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_APS_TYPES_URL);
      return response.data;
    } catch (error) {
      console.error("Error fetching APS types:", error);
      return rejectWithValue(
        error.response?.data || "Error fetching APS types"
      );
    }
  }
);

const dmaApsSlice = createSlice({
  name: "dmaAps",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    types: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAps.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAps.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(fetchApsTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchApsTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.types = action.payload;
      })
      .addCase(fetchApsTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(createAps.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createAps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createAps.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(updateAps.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(updateAps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateAps.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(deleteAps.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteAps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteAps.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      });
  },
});

export default dmaApsSlice.reducer;
