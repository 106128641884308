import languageData from '../../constants/languages/en/translations.json';

export const newProjectFormFields = [
    {
      id: "projectName",
      label: languageData.projectNameLabel,
      placeholder: languageData.projectNamePlaceholder,
      type: "input",
      class: "",
    },
    {
      id: "clientName",
      label: languageData.clientNameLabel,
      placeholder: languageData.clientNamePlaceholder,
      type: "input",
      class: "",
    },
    {
      id: "countryId",
      label: languageData.countryLabel,
      placeholder: languageData.countryPlaceholder,
      type: "select",
      options: [
        { value: 1, label: "Afghanistan" },
        { value: 2, label: "Albania" },
      ],
      class: "",
      filter: true
    },
    {
      id: "engagementCode",
      label: languageData.engagementCodeLabel,
      placeholder: languageData.engagementCodePlaceholder,
      type: "input",
      class: "",
    },
    {
      id: "channel",
      label: languageData.channelLabel,
      placeholder: languageData.channelPlaceholder,
      type: "select",
      options: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" }
      ],
      class: "",
      filter: false
    },
    {
      id: "projectSize",
      label: languageData.projectSizeLabel,
      placeholder: languageData.projectSizePlaceholder,
      type: "select",
      options: [
        { value: "demo", label: "Demo" },
        { value: "<50k", label: "<50k" },
        { value: "50k-100k", label: "50k-100k" },
        { value: "100k-300k", label: "100k-300k" },
        { value: "300k-500k", label: "300k-500k" },
        { value: "500k-1000k", label: "500k-1000k" },
        { value: ">1000k", label: ">1000k" }
      ],
      class: "",
      filter: false
    },
    {
      id: "clientSector",
      label: languageData.clientSectorLabel,
      placeholder: languageData.clientSectorPlaceholder,
      type: "select",
      options: [
        { value: 1, label: "Advanced Manufacturing" },
        { value: 2, label: "Advanced Manufacturing" },
        { value: 3, label: "Advanced Manufacturing" }
      ],
      class: "full-width",
      filter: true
    },
    {
      id: "projectAdmin",
      label: languageData.projectAdministratorLabel,
      placeholder: languageData.projectAdministratorPlaceholder,
      type: "select",
      multiple: true,
      options: [],
      class: "full-width"
    }
];