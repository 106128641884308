import { React, useState , useMemo} from "react";
import "./ClientOrg.scss";
import Header from "../../components/headers/Header";
import { TableCell, Box, IconButton, Link } from "@mui/material";
import { Edit, Delete } from "../../constants/icons/Icons";
import Subheader from "../../components/subheader/Subheader";
import languageData from "../../constants/languages/en/translations.json";
import ClientTable from "../../components/clientTable/ClientTable";
import { columns, data } from "./ClientOrgData";
import AddUser from "../../components/modals/addUser/AddUser";
import language from "../../constants/languages/en/translations.json";

const ClientOrg = () => {
  const isAdmin = true;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const handleSearch = (event) => {
    //Write Search function here
  };

  // Columns that are displayed
  const columnsToDisplay = useMemo(
    () => [
      { colName: "Name", label: "Name", showSorting: true },
      { colName: "Type", label: "Type", showSorting: true },
      { colName: "Email", label: "Email", showSorting: true },
      { colName: "Action", label: "Action", showSorting: false },
    ],
    []
  );

  const generateRowKey = (row) => row.userId;

  const getCellStyle = () => ({ padding: "15px" });

  const renderTableCell = (column, value, row, index) => {
    const cellStyle = getCellStyle(column, index);
    const cellPaddingRight = index === columns.length - 1 ? "3%" : "0";

    if (column === "Instance Link") {
      return (
        <TableCell key={column} style={cellStyle}>
          <Link href={value}>{language.ViewInstances}</Link>
        </TableCell>
      );
    } else if (column === "Action") {
      return (
        <TableCell key={column} style={cellStyle}>
          <Box display="flex" justifyContent="flex-end" paddingRight="10%">
            <IconButton onClick={() => handleButtonClick(row, "Edit")}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleButtonClick(row, "Delete")}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    } else {
      return (
        <TableCell
          key={column}
          style={{ ...cellStyle, paddingRight: cellPaddingRight }}
        >
          {value}
        </TableCell>
      );
    }
  };

  const handleButtonClick = (row, buttonName) => {
    //console.log(`Button "${buttonName}" clicked for row:`, row);
    //write button click function here
  };

  return (
    <div className="all-client-container">
      <Header />
      <div className="client-org-container">
        <div className="client-org-subheader">
          <Subheader
            isAdmin={isAdmin}
            page={languageData.ClientOrgAccessTitle}
            title={languageData.ClientOrganisation}
            onChangeSearchInput={handleSearch}
            handleOpenModal={handleOpenModal}
            CreateNewEN={languageData.AddNew}
            ClientOrgAccessTitleEN={languageData.ClientOrgAccessTitle}
          />
          <AddUser
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            page={languageData.ClientOrgPage}
            ClientOrgPageEN={languageData.ClientOrgPage}
            TitleClientOrgEN={languageData.TitleClientOrg}
            TypeEN={languageData.TypeClientOrganization}
            SelectEN={languageData.Select}
            EmailEN={languageData.Email}
            EmailContentEN={languageData.EmailContent}
            CancelButtonEN={languageData.CancelButton}
            DoneButtonEN={languageData.DoneButton}
            SelectContent1EN={languageData.SelectContent1}
            SelectContent2EN={languageData.SelectContent2}
            SelectContent3EN={languageData.SelectContent3}
            UserNameEN={languageData.UserName}
            UserNameContentEN={languageData.UserNameContent}
          />
        </div>
        <div>
          <ClientTable
            columns={columnsToDisplay}
            data={data}
            itemsPerPage={5}
            generateRowKey={generateRowKey}
            getCellStyle={getCellStyle}
            renderTableCell={renderTableCell}
            handleButtonClick={handleButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientOrg;
