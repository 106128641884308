import React, { useState, useEffect } from "react";
import "./NewProject.scss";
import {
  MotifCard,
  MotifCardBody,
  MotifCardFooter,
  MotifCardHeader,
  MotifButton,
} from "@ey-xd/motif-react";
import Header from "../../components/headers/Header";
import NewProjectForm from "../../components/forms/NewProjectForm";
import { newProjectFormFields } from "./NewProjectData";
import languageData from '../../constants/languages/en/translations.json';
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectDetails,
  updateProjectDetails,
  retireProject
} from "../../features/slices/ProjectScreens";
import {
  fetchAllUsers
} from "../../features/slices/UsersData";
import { useParams, useNavigate } from "react-router-dom";
import { Back, Cross } from "../../constants/icons/Icons";
import RetirePopup from "../../components/modals/addUser/AddUser";

const EditProject = (props) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { data, isError, errorMessage } = useSelector(
    (state) => state.projectDetails
  );
  const {data : allUsers} = useSelector(state => state.allUsers);
  const [formFields, setFormFields] = useState(newProjectFormFields);
  const [formState, setFormState] = useState({
    projectName: "",
    clientName: "",
    engagementCode: "",
    countryId: "",
    channel: "",
    projectSize: "",
    clientSector: "",
    projectAdmin: [],
  });

  const [constantProjectName, setConstantProjectName] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const goToProjectScreen = () => {
    navigate(`/project-home/${projectId}`);
  }

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectDetails(projectId));
      dispatch(fetchAllUsers());
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    if (data?.data) {
      const userIDs = data.data.users?.map(user => user.user.id) || [];
      setFormState({
        projectName: data.data.projectName || "",
        clientName: data.data.clientName || "",
        engagementCode: data.data.engagementCode || "",
        countryId: data.data.countryId || "",
        channel: data.data.isChannel1 ? "yes" : "no",
        projectSize: data.data.projectSize || "",
        clientSector: data.data.sectorId || "",
        projectAdmin: userIDs || [],
      });
      setConstantProjectName(data.data.projectName || "");
    }
  }, [data]);

  useEffect(() => {
    if (allUsers?.data) {
      const updatedFields = formFields.map(field => {
        if (field.id === "projectAdmin") {
          return {
            ...field,
            options: allUsers.data.map(user => ({
              value: user.id,
              label: user.userName
            }))
          };
        }
        return field;
      });
      setFormFields(updatedFields);
    }
  }, [allUsers, formFields]);
  

  const isFormValid = () => {
    return (
      formState.projectName.trim() !== "" &&
      formState.clientName.trim() !== "" &&
      formState.countryId > 0 &&
      formState.channel.trim() !== "" &&
      formState.projectSize.trim() !== "" &&
      formState.clientSector > 0 &&
      formState.projectAdmin.length > 0
    );
  };

  const handleUpdate = () => {
    if (isFormValid()) {
      setIsUpdating(true);
      dispatch(
        updateProjectDetails({
          id: projectId,
          projectName: formState.projectName,
          clientName: formState.clientName,
          engagementCode: formState.engagementCode,
          countryId: formState.countryId,
          isChannel1: formState.channel === "yes",
          projectSize: formState.projectSize,
          adminUsersId: formState.projectAdmin,
          statusId: 1, // means project is active
          sectorId: formState.clientSector,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.success) {
            goToProjectScreen();
          }
        })
        .catch((error) => {
          console.error("Update failed:", error);
          alert("Failed to update project: " + error.message);
        })
        .finally(() => setIsUpdating(false));
    } else {
      alert("Please fill in all required fields.");
    }
  };

  const handleDeleteOpenModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleRetireConfirm = () => {
    // user id is static now. This will get changed after dynamic login
    const userId = "caee3e1b-ffd5-4630-994a-08dc764f139b"
    dispatch(retireProject({ projectId, adminId : userId }))
      .unwrap()
      .then(() => {
        navigate('/');
        handleDeleteCloseModal();
      })
      .catch((error) => {
        console.error("Retire failed:", error);
        alert("Failed to retire project: " + error);
      });
  };

  return (
    <div>
      <Header />
      <div className="Container">
        <div id="inline">
          <MotifButton
            className="button done-button"
            onClick={goToProjectScreen}
          >
            {" "}
            <Back />
            &nbsp;
            {languageData.backButton}
          </MotifButton>
        </div>
        <MotifCard
          orientation="horizontal"
          variant="card-border"
          className="new-project-card"
        >
          {isError && errorMessage && (
            <div>
              {errorMessage.errors &&
                Object.entries(errorMessage.errors).map(([key, value]) => (
                  <p className="error-message" key={key}>
                    {key}: {value.join(", ")}
                  </p>
                ))}
            </div>
          )}
          <MotifCardHeader>
            <span className="motif-h6-default-regular">
              {languageData.editProject} {constantProjectName}
            </span>
          </MotifCardHeader>
          <MotifCardBody>
            <NewProjectForm
              formFields={formFields}
              formState={formState}
              setFormState={setFormState}
            />
          </MotifCardBody>
          <MotifCardFooter className="edit-project-button-group">
          <MotifButton className="button retire-button" variant="warn" warn={true} onClick={handleDeleteOpenModal}>
              {languageData.retireButton}
              {" "}
              &nbsp;
              <Cross />
            </MotifButton>
            <div className="new-project-footer">
            <MotifButton className="button cancel-button" onClick={goToProjectScreen}>
              {languageData.cancelButton}
            </MotifButton>
            <MotifButton
              className="button done-button"
              onClick={handleUpdate}
              disabled={!isFormValid() || isUpdating}
            >
              {isUpdating ? languageData.updatingButton : languageData.updateButton}
            </MotifButton>
            </div>
          </MotifCardFooter>
        </MotifCard>
      </div>
      <RetirePopup
              isOpen={isDeleteModalOpen}
              onClose={handleDeleteCloseModal}
              page={languageData.ConfirmationPopup}
              ConfirmationPopup={languageData.ConfirmationPopup}
              ConfirmationTitle={languageData.ConfirmationTitle}
              ConfirmationEN={languageData.ConfirmationEN}
              ConfirmationDeleteMsgEN={languageData.ConfirmationRetireProject}
              CancelButtonEN={languageData.cancelButton}
              callApi={handleRetireConfirm}
            />
    </div>
  );
};

export default EditProject;