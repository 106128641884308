import React, { useState } from "react";
import Stepper from "../../components/stepper/Stepper";
import Header from "../../components/headers/Header";
import {
  Stake,
  Beat,
  Iro,
  IroLite,
  ReportingLite,
  Reporting,
  BeatLite,
  StakeLite,
  StakeHolder,
  StakeHolderLite,
} from "../../constants/icons/Icons";
import languageEN from "../../constants/languages/en/translations.json";
import DmaContext from "./dmaContext/DmaContext";
import "./DMA.scss";
import DMAOverview from "./dmaOverview/DMAOverview";
import DmaProcessSpecification from "./dmaProcessSpecification/DmaProcessSpecification";

const DMA = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleStepChange = (index) => {
    setActiveIndex(index);
  };

  const getIcon = (index, activeIcon, inactiveIcon) => {
    return activeIndex === index ? activeIcon : inactiveIcon;
  };

  return (
    <div>
      <Header />
      <Stepper onChange={handleStepChange}>
        <div
          icon={getIcon(0, <Beat />, <BeatLite />)}
          text={languageEN.Overview}
        >
          <DMAOverview />
        </div>
        <div
          icon={getIcon(1, <Stake />, <StakeLite />)}
          text={languageEN.UnderstandTheContext}
        >
          <DmaContext />
        </div>
        <div
          icon={getIcon(2, <StakeHolder />, <StakeHolderLite />)}
          text={languageEN.StakeholderOutreach}
        >
          {/* Content for Stakeholder outreach section */}
        </div>
        <div
          icon={getIcon(3, <Beat />, <BeatLite />)}
          text={languageEN.ProcessSpecification}
        >
          <DmaProcessSpecification />
        </div>
        <div
          icon={getIcon(4, <Iro />, <IroLite />)}
          text={languageEN.IROIdentificationAndAssessment}
        >
          {/* Content for IRO identification and assessment section */}
        </div>
        <div
          icon={getIcon(5, <Reporting />, <ReportingLite />)}
          text={languageEN.ReportingImplication}
        >
          {/* Content for Reporting implication section */}
        </div>
      </Stepper>
    </div>
  );
};

export default DMA;
