import React, { useState, useEffect } from "react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit, Delete } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import GlModal from "../../../../components/modals/dmaModals/GlModal";
import { useDispatch, useSelector } from "react-redux";
import languageEN from "../../../../constants/languages/en/translations.json";
import {
  fetchAllGl,
  createGl,
  updateGl,
  deleteGl,
} from "../../../../features/slices/DMAGeographicalLocation";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useParams } from "react-router-dom";
import Pagination from "../../../../components/paginationForCards/Pagination";
import TopBar from "../Topbar";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import "./GeographicalLocation.scss";

const GeographicalLocation = () => {
  const [isGlTableView, setIsGlTableView] = useState(true);
  const [isGlModalOpen, setIsGlModalOpen] = useState(false);
  const [modalGlMode, setModalGlMode] = useState("add");
  const [modalGlTitle, setModalGlTitle] = useState("");
  const [selectedGlRow, setSelectedGlRow] = useState(null);
  const [currentGlPage, setCurrentGlPage] = useState(1);
  const [searchGlQuery, setSearchGlQuery] = useState("");
  const [isGlDeleteModalOpen, setIsGlDeleteModalOpen] = useState(false);
  const [glToDelete, setGlToDelete] = useState(null);
  const { moduleId } = useParams();
  const itemsPerPage = 10;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllGl(moduleId));
  }, [dispatch, moduleId]);
  const {
    data: responseData,
    isLoading,
    isError,
  } = useSelector((state) => state.allGldata);

  useEffect(() => {
    if (responseData?.data) {
      setGlData(responseData.data);
    }
  }, [responseData]);

  const [glData, setGlData] = useState([]);

  const handleEdit = (row) => {
    setSelectedGlRow(row);
    setModalGlMode("edit");
    setModalGlTitle("Edit geographical location");
    setIsGlModalOpen(true);
  };

  const handleDelete = async () => {
    if (!glToDelete) return;

    try {
      const response = await dispatch(deleteGl({ glId: glToDelete.id }));
      if (!response.error) {
        setGlData((prevData) => {
          if (Array.isArray(prevData)) {
            return prevData.filter((gl) => gl.id !== glToDelete.id);
          } else {
            return prevData;
          }
        });
        dispatch(fetchAllGl(moduleId));
      } else {
        console.error("Error deleting data", response.error);
      }
    } catch (error) {
      console.error("Error deleting data", error);
    } finally {
      setIsGlDeleteModalOpen(false);
    }
  };

  const openDeleteModal = (row) => {
    setGlToDelete(row);
    setIsGlDeleteModalOpen(true);
  };

  const handleSourceValueClick = async (glData) => {
    try {
      let response;
      if (modalGlMode === "add") {
        response = await dispatch(createGl({ moduleId, glData }));
        if (response.payload) {
          setGlData((prevData) => [...prevData, response.payload]);
          handleCloseModal();
          dispatch(fetchAllGl(moduleId));
        } else {
          console.error("Error creating GL:", response.error);
        }
      } else if (modalGlMode === "edit" && selectedGlRow) {
        response = await dispatch(updateGl({ glId: selectedGlRow.id, glData }));
        if (response.payload) {
          setGlData((prevData) =>
            Array.isArray(prevData)
              ? prevData.map((gl) =>
                  gl.id === response.payload.id ? response.payload : gl
                )
              : [response.payload]
          );
          handleCloseModal();
          dispatch(fetchAllGl(moduleId));
        } else {
          console.error("Error updating APS:", response.error);
        }
      }
      handleCloseModal();
      dispatch(fetchAllGl(moduleId));
    } catch (error) {
      console.error("Error creating/updating GL:", error);
    }
  };

  const renderGlTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ textAlign: "right", paddingRight: "2.55%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => openDeleteModal(row)}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    return (
      <TableCell key={index} className="truncate">
        {value}
      </TableCell>
    );
  };

  const generateRowKey = (row) => row.id;

  const handleOpenModal = (mode) => {
    setModalGlMode(mode);
    setSelectedGlRow(null);
    if (mode === "add") {
      setModalGlTitle("New geographical location");
    } else if (mode === "edit") {
      setModalGlTitle("Edit geographical location");
    }
    setIsGlModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsGlModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentGlPage(page);
  };

  const handleSearch = (event) => {
    setSearchGlQuery(String(event));
  };

  const getGlFilteredData = () => {
    return Array.isArray(glData)
      ? glData.filter((item) =>
          item.name?.toLowerCase().includes(searchGlQuery.toLowerCase())
        )
      : [];
  };

  const paginatedData = getGlFilteredData().slice(
    (currentGlPage - 1) * itemsPerPage,
    currentGlPage * itemsPerPage
  );

  if (isError) {
    return <div>Error loading data.</div>;
  }
  const columnsGl = [
    { colName: "name", label: "Name", showSorting: true },
    { colName: "description", label: "Description", showSorting: true },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  const totalPages = Math.ceil(getGlFilteredData().length / itemsPerPage);

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleOpenModal("add")}
        onChangeSearchInput={handleSearch}
      />
      <TopBar
        isTableView={isGlTableView}
        totalResults={glData.length}
        filteredResults={getGlFilteredData().length}
        onTableViewClick={() => setIsGlTableView(true)}
        onCardViewClick={() => setIsGlTableView(false)}
      />

      <div className="gl-card-table-switch">
        {isGlTableView ? (
          <ClientTable
            columns={columnsGl}
            data={getGlFilteredData()}
            itemsPerPage={5}
            renderTableCell={(colName, value, row, index) => {
              return renderGlTableCell(colName, value, row, index);
            }}
            generateRowKey={generateRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                descriptionHeaderText={item.description}
                handleEdit={() => handleEdit(item)}
                handleDelete={() => openDeleteModal(item)}
              />
            ))}
          </div>
        )}
      </div>
      {!isGlTableView && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentGlPage}
          onPageChange={handlePageChange}
        />
      )}

      {isGlModalOpen && (
        <GlModal
          onClose={handleCloseModal}
          setName={modalGlTitle}
          handleStatusChange={() => {}}
          handleSourceValueClick={handleSourceValueClick}
          modalMode={modalGlMode}
          NameLabel={languageEN.Name}
          DescriptionLabel={languageEN.Description}
          cancelButtonLabel={languageEN.Cancel}
          saveButtonLabel={languageEN.AddToList}
          updateButtonLabel={languageEN.Update}
          selectedRow={selectedGlRow}
        />
      )}
      {isGlDeleteModalOpen && (
        <DeleteModal
          isOpen={isGlDeleteModalOpen}
          onClose={() => setIsGlDeleteModalOpen(false)}
          setName={languageEN.DeleteLabel}
          labelText={languageEN.DeletConfirmationSources}
          onCancel={() => setIsGlDeleteModalOpen(false)}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={languageEN.Cancel}
          deleteButtonLabel={languageEN.DeleteLabel}
        />
      )}
    </div>
  );
};

export default GeographicalLocation;
