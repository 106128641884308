import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifMessage,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import "./AddUser.scss";
import PropTypes from "prop-types";

const AddUser = ({
  isOpen,
  onClose,
  page,
  ClientOrgPageEN,
  TitleClientOrgEN,
  TitleUserManagementEN,
  UserNameEN,
  UserNameContentEN,
  TypeEN,
  SelectEN,
  RoleEN,
  SelectRoleEN,
  EmailEN,
  UserEmailEN,
  EmailContentEN,
  CancelButtonEN,
  DoneButtonEN,
  SelectContent1EN,
  SelectContent2EN,
  SelectContent3EN,
  UserManagementPageEN,
  SelectRole1EN,
  SelectRole2EN,
  AddModulePopupEN,
  NewModuleTitleEN,
  SessionNameEN,
  AddSessionPopupEN,
  SelectedsessionName,
  ModuleEN,
  SelectModuleEN,
  DMAEN,
  HLEN,
  DDEN,
  NewSessionTitleEN,
  SessionNameContentEN,
  SessionFrameworkEN,
  SelectSessionFrameworkEN,
  ESRSEN,
  ISSBEN,
  GRIEN,
  projectId,
  sessionId,
  callApi,
  TitleUpdateUserManagementEN,
  UpdateUserManagementPageEN,
  selectedUsername,
  selectedRole,
  selectedEmail,
  UpdateButtonEN,
  ConfirmationPopup,
  ConfirmationTitle,
  ConfirmationEN,
  ConfirmationDeleteMsgEN,
}) => {
  const [userName, setUserName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [userFramework, setUserFramework] = useState("");
  const [module, setModule] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [sessionFramework, setSessionFramework] = useState("");

  useEffect(() => {
    setUserName(selectedUsername);
    setEmailId(selectedEmail);
  }, [selectedUsername, selectedEmail]);

  const handleDone = () => {
    switch (page) {
      case AddSessionPopupEN:
        callApi({
          sessionName: sessionName,
          sessionFramework: sessionFramework,
        });
        break;

      case AddModulePopupEN:
        callApi({
          moduleName: module,
          sessionId: sessionId,
        });
        break;

      case UserManagementPageEN:
        callApi({
          userName,
          email: emailId,
          roleName: userFramework,
        });
        break;

      case UpdateUserManagementPageEN:
        callApi({ newRoleName: userFramework });
        break;

      default:
        callApi();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>
            {page === ClientOrgPageEN && TitleClientOrgEN}
            {page === UserManagementPageEN && TitleUserManagementEN}
            {page === AddSessionPopupEN && NewSessionTitleEN}
            {page === AddModulePopupEN && NewModuleTitleEN}
            {page === UpdateUserManagementPageEN && TitleUpdateUserManagementEN}
            {page === ConfirmationPopup && ConfirmationTitle}
          </h2>
        </div>
        <div className="modal-body">
          {page !== ConfirmationPopup && (
            <div className="form-row">
              <div className="form-group">
                <MotifMessage id="form-title">
                  {(page === ClientOrgPageEN ||
                    page === UserManagementPageEN ||
                    page === UpdateUserManagementPageEN) &&
                    UserNameEN}
                  {(page === AddModulePopupEN || page === AddSessionPopupEN) &&
                    SessionNameEN}
                </MotifMessage>
                {(page === ClientOrgPageEN ||
                  page === UserManagementPageEN) && (
                  <MotifFormField>
                    <MotifLabel>{UserNameContentEN}</MotifLabel>
                    <MotifInput
                      className="user-modal-input"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      aria-describedby="user-name"
                      hideClearButton={true}
                      data-testid="username"
                    />
                  </MotifFormField>
                )}
                {page === UpdateUserManagementPageEN && (
                  <MotifFormField>
                    <MotifLabel>{UserNameContentEN}</MotifLabel>
                    <MotifInput
                      className="user-modal-input"
                      value={selectedUsername}
                      onChange={(e) => setUserName(e.target.value)}
                      aria-describedby="user-name"
                      hideClearButton={true}
                      data-testid="username"
                      disabled={true}
                    />
                  </MotifFormField>
                )}
                {page === AddModulePopupEN && (
                  <MotifFormField>
                    <MotifInput
                      className="session-modal-input session-name-input"
                      value={SelectedsessionName}
                      aria-describedby="session-name"
                      hideClearButton={true}
                      disabled={true}
                    />
                  </MotifFormField>
                )}
                {page === AddSessionPopupEN && (
                  <MotifFormField>
                    <MotifLabel>{SessionNameContentEN}</MotifLabel>
                    <MotifInput
                      className="session-modal-input session-name-input"
                      value={sessionName}
                      onChange={(e) => setSessionName(e.target.value)}
                      aria-describedby="session-name"
                      hideClearButton={true}
                    />
                  </MotifFormField>
                )}
              </div>
              {page === ClientOrgPageEN && (
                <div className="form-group">
                  <MotifMessage id="form-title">{TypeEN}</MotifMessage>
                  <MotifFormField>
                    <MotifLabel id="select-label">{SelectEN}</MotifLabel>
                    <MotifSelect
                      className="user-modal-input"
                      ariaLabelledBy="select-label"
                      onChange={function noRefCheck() {}}
                      placeholder="placeholder"
                      value={userFramework}
                      visibleOptions={3}
                      data-testid="userframework"
                    >
                      <MotifOption value="Individual">
                        {SelectContent1EN}
                      </MotifOption>
                      <MotifOption value="Function">
                        {SelectContent2EN}
                      </MotifOption>
                      <MotifOption value="Department">
                        {SelectContent3EN}
                      </MotifOption>
                    </MotifSelect>
                  </MotifFormField>
                </div>
              )}

              {page === UserManagementPageEN && (
                <div className="form-group">
                  <MotifMessage id="form-title">{RoleEN}</MotifMessage>
                  <MotifFormField>
                    <MotifLabel id="select-label">{SelectRoleEN}</MotifLabel>
                    <MotifSelect
                      className="user-modal-input"
                      value={userFramework}
                      ariaLabelledBy="select-label"
                      onChange={(e) => setUserFramework(e)}
                      placeholder="placeholder"
                      visibleOptions={2}
                      data-testid="userframework"
                    >
                      <MotifOption value="Project Member">
                        {SelectRole2EN}
                      </MotifOption>
                      <MotifOption value="Project Admin">
                        {SelectRole1EN}
                      </MotifOption>
                    </MotifSelect>
                  </MotifFormField>
                </div>
              )}
              {page === UpdateUserManagementPageEN && (
                <div className="form-group">
                  <MotifMessage id="form-title">{RoleEN}</MotifMessage>
                  <MotifFormField>
                    <MotifLabel id="select-label">{SelectRoleEN}</MotifLabel>
                    <MotifSelect
                      className="user-modal-input"
                      value={userFramework}
                      ariaLabelledBy="select-label"
                      onChange={(e) => setUserFramework(e)}
                      placeholder="placeholder"
                      visibleOptions={2}
                      data-testid="userframework"
                    >
                      <MotifOption value="Project Member">
                        {SelectRole2EN}
                      </MotifOption>
                      <MotifOption value="Project Admin">
                        {SelectRole1EN}
                      </MotifOption>
                    </MotifSelect>
                  </MotifFormField>
                </div>
              )}

              {page === AddModulePopupEN && (
                <div className="form-group">
                  <MotifMessage id="module-framework">{ModuleEN}</MotifMessage>
                  <MotifFormField>
                    <MotifLabel id="select-label">{SelectModuleEN}</MotifLabel>
                    <MotifSelect
                      className="module-modal-input"
                      ariaLabelledBy="select-label"
                      onChange={(e) => setModule(e)}
                      placeholder="placeholder"
                      value={module}
                      visibleOptions={3}
                    >
                      <MotifOption value="DMA">{DMAEN}</MotifOption>
                      <MotifOption value="HL">{HLEN}</MotifOption>
                      <MotifOption value="DD">{DDEN}</MotifOption>
                    </MotifSelect>
                  </MotifFormField>
                </div>
              )}

              {page === AddSessionPopupEN && (
                <div className="form-group">
                  <MotifMessage id="session-framework">
                    {SessionFrameworkEN}
                  </MotifMessage>
                  <MotifFormField>
                    <MotifLabel id="select-label">
                      {SelectSessionFrameworkEN}
                    </MotifLabel>
                    <MotifSelect
                      className="session-modal-input"
                      ariaLabelledBy="select-label"
                      onChange={(e) => setSessionFramework(e)}
                      placeholder="placeholder"
                      value={sessionFramework}
                      visibleOptions={3}
                    >
                      <MotifOption value="ESRS">{ESRSEN}</MotifOption>
                      <MotifOption value="ISSB">{ISSBEN}</MotifOption>
                      <MotifOption value="GRI">{GRIEN}</MotifOption>
                    </MotifSelect>
                  </MotifFormField>
                </div>
              )}
            </div>
          )}
          {(page === ClientOrgPageEN || page === UserManagementPageEN) && (
            <div className="form-row">
              <div className="form-group-email">
                <MotifMessage id="form-title">
                  {page === ClientOrgPageEN ? EmailEN : UserEmailEN}
                </MotifMessage>
                <MotifFormField>
                  <MotifLabel className="form-label">
                    {EmailContentEN}
                  </MotifLabel>
                  <MotifInput
                    className="email-modal-input"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    aria-describedby="email"
                    hideClearButton={true}
                    data-testid="useremail"
                  />
                </MotifFormField>
              </div>
            </div>
          )}
          {page === UpdateUserManagementPageEN && (
            <div className="form-row">
              <div className="form-group-email">
                <MotifMessage id="form-title">{UserEmailEN}</MotifMessage>
                <MotifFormField>
                  <MotifLabel className="form-label">
                    {EmailContentEN}
                  </MotifLabel>
                  <MotifInput
                    className="email-modal-input"
                    value={selectedEmail}
                    onChange={(e) => setEmailId(e.target.value)}
                    aria-describedby="email"
                    hideClearButton={true}
                    data-testid="useremail"
                    disabled={true}
                  />
                </MotifFormField>
              </div>
            </div>
          )}
          {page === ConfirmationPopup && (
            <div className="form-row">
              <div className="form-group-email">
                <MotifMessage id="form-title">
                  {ConfirmationDeleteMsgEN}
                </MotifMessage>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {CancelButtonEN}
          </MotifButton>
          <MotifButton className="button done-button" onClick={handleDone}>
            {page === UpdateUserManagementPageEN
              ? UpdateButtonEN
              : DoneButtonEN}
            {page === ConfirmationPopup && ConfirmationEN}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

AddUser.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  page: PropTypes.string,
  ClientOrgPageEN: PropTypes.string,
  TitleClientOrgEN: PropTypes.string,
  TitleUserManagementEN: PropTypes.string,
  UserNameEN: PropTypes.string,
  UserNameContentEN: PropTypes.string,
  TypeEN: PropTypes.string,
  SelectEN: PropTypes.string,
  RoleEN: PropTypes.string,
  SelectRoleEN: PropTypes.string,
  EmailEN: PropTypes.string,
  UserEmailEN: PropTypes.string,
  EmailContentEN: PropTypes.string,
  CancelButtonEN: PropTypes.string,
  DoneButtonEN: PropTypes.string,
  SelectContent1EN: PropTypes.string,
  SelectContent2EN: PropTypes.string,
  SelectContent3EN: PropTypes.string,
  UserManagementPageEN: PropTypes.string,
  SelectRole1EN: PropTypes.string,
  SelectRole2EN: PropTypes.string,
  AddModulePopupEN: PropTypes.string,
  NewModuleTitleEN: PropTypes.string,
  SessionNameEN: PropTypes.string,
  AddSessionPopupEN: PropTypes.string,
  SelectedsessionName: PropTypes.string,
  ModuleEN: PropTypes.string,
  SelectModuleEN: PropTypes.string,
  DMAEN: PropTypes.string,
  HLEN: PropTypes.string,
  DDEN: PropTypes.string,
  NewSessionTitleEN: PropTypes.string,
  SessionNameContentEN: PropTypes.string,
  SessionFrameworkEN: PropTypes.string,
  SelectSessionFrameworkEN: PropTypes.string,
  ESRSEN: PropTypes.string,
  ISSBEN: PropTypes.string,
  GRIEN: PropTypes.string,
  projectId: PropTypes.string,
  sessionId: PropTypes.string,
  callApi: PropTypes.func,
  TitleUpdateUserManagementEN: PropTypes.string,
  UpdateUserManagementPageEN: PropTypes.string,
  selectedUsername: PropTypes.string,
  selectedRole: PropTypes.string,
  selectedEmail: PropTypes.string,
  UpdateButtonEN: PropTypes.string,
  ConfirmationPopup: PropTypes.string,
  ConfirmationTitle: PropTypes.string,
  ConfirmationEN: PropTypes.string,
  ConfirmationDeleteMsgEN: PropTypes.string,
};

export default AddUser;
