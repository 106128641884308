import React, { useState, useEffect } from "react";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./style.scss";
import Header from "../../components/headers/Header";
import SessionModulelanguageData from "../projectHome/sessionAccordion/Session_Module.json";
import Accordion from "../projectHome/sessionAccordion/SessionAccordion";
import ProjectDetails from "./projectDetails/ProjectDetails";
import Subheader from "../../components/subheader/Subheader";
import languageData from "../../constants/languages/en/translations.json";
import { useParams, useNavigate } from "react-router-dom";
import AddUser from "../../components/modals/addUser/AddUser";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectDetails,
  createSessionInProject,
  createModuleInProject
} from "../../features/slices/ProjectScreens";

const ProjectHome = () => {
  const isAdmin = true;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModuleModalOpen, setIsModuleModalOpen] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [modalType,setModalType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sessionId, setSessionId] = useState("");
  // Accessing the projectId from the URL
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const { data, isError, errorMessage, isLoading } = useSelector(
    (state) => state.projectDetails
  );

  useEffect(() => {
    dispatch(fetchProjectDetails(projectId));
  }, [dispatch, projectId, updateTrigger]);

  const handleSessionModal = () => {
    setIsModalOpen(true);
    setModalType(languageData.AddSessionPopup);
  };

  const handleModuleModal = (sessionName,id) => {
    setIsModuleModalOpen(true);
    setSessionName(sessionName);
    setSessionId(id);
    setModalType(languageData.AddModulePopup);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModuleCloseModal = () => {
    setIsModuleModalOpen(false);
  };

  const handleClientOrg = () => {
    navigate("/client-org/" + projectId);
  };

  const handleUserManagement = () => {
    navigate("/user-management/" + projectId);
  };

  const handleEditNavigate = () => {
    navigate("/edit-project/" + projectId);
  };

  const createSession = async (sessionData) => {
    // API call to create a session
    if (sessionData.sessionName && sessionData.sessionFramework) {
      sessionData.projectId = projectId;
      dispatch(createSessionInProject(sessionData))
        .unwrap()
        .then((response) => {
          if (response.success) {
            setUpdateTrigger((prev) => prev + 1);
            handleCloseModal();
          }
        })
        .catch((error) => {
          console.error("creation failed:", error);
          alert("Failed to create session: " + error.message);
        });
    } else {
      alert("Please fill in all required fields");
    }
  };

  const createModule = (moduleData) => {
    // Implement actual API call here
    if (moduleData.sessionId && moduleData.moduleName) {
      dispatch(createModuleInProject(moduleData))
        .unwrap()
        .then((response) => {
          if (response.success) {
            setUpdateTrigger((prev) => prev + 1);
            handleModuleCloseModal();
          }
        })
        .catch((error) => {
          console.error("creation failed:", error);
          alert("Failed to create module: " + error.message);
        });
    } else {
      alert("Please fill in all required fields");
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event);
  };

  return (
    <div>
      {isLoading && <MotifProgressLoader show variant="default" />}
      <Header languageData-testid="header" />
      <div className="Container">
        <Subheader
          isAdmin={isAdmin}
          page={languageData.ProjectHomeAccessTitle}
          title={
            data?.data ? data.data.projectName : languageData.ProjectTitle
          }
          languageData-testid="subheader"
          handleSessionModal={handleSessionModal}
          handleClientOrg={handleClientOrg}
          handleUserManagement={handleUserManagement}
          ProjectHomeAccessTitleEN={languageData.ProjectHomeAccessTitle}
          ClientOrgTitleEN={languageData.ClientOrgTitle}
          UserManagementTitleEN={languageData.UserManagementTitle}
          NewSessionTitleEN={languageData.NewSessionTitle}
          onChangeSearchInput={handleSearch}
        />
        {modalType === languageData.AddSessionPopup && (
          <AddUser
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            projectId={projectId}
            NewSessionTitleEN={languageData.NewSessionTitle}
            SessionNameContentEN={languageData.SessionNameContent}
            SessionFrameworkEN={languageData.SessionFramework}
            SelectSessionFrameworkEN={languageData.SelectSessionFramework}
            ESRSEN={languageData.ESRS}
            ISSBEN={languageData.ISSB}
            GRIEN={languageData.GRI}
            CancelButtonEN={languageData.CancelButton}
            DoneButtonEN={languageData.DoneButton}
            page={languageData.AddSessionPopup}
            SessionNameEN={languageData.SessionName}
            AddSessionPopupEN={languageData.AddSessionPopup}
            callApi={createSession}
          />
        )}
        {isError && <p>Error: {errorMessage}</p>}
        {isAdmin && (
          <ProjectDetails
            languageData-testid="project-details"
            ProjectDetailTitleEN={languageData.ProjectDetailTitle}
            EditEN={languageData.Edit}
            projectName={languageData.ProjectName}
            clientName={languageData.ClientName}
            country={languageData.Country}
            engagementcode={languageData.Engagementcode}
            projectSizeUSD={languageData.ProjectSizeUSD}
            clientSector={languageData.ClientSector}
            projectAdministrator={languageData.ProjectAdministrator}
            data={data?.data || []}
            handleEditNavigate={handleEditNavigate}
          />
        )}
        <Accordion
          data={data?.data?.sessions || SessionModulelanguageData || []}
          isAdmin={isAdmin}
          languageData-testid="accordion"
          handleModuleModal={handleModuleModal}
          ModuleTitleEN={languageData.ModuleTitle}
        />
        {modalType === languageData.AddModulePopup && (
          <AddUser
            isOpen={isModuleModalOpen}
            onClose={handleModuleCloseModal}
            sessionId={sessionId}
            SelectedsessionName={sessionName}
            AddModulePopupEN={languageData.AddModulePopup}
            NewModuleTitleEN={languageData.NewModuleTitle}
            SessionNameEN={languageData.SessionName}
            ModuleEN={languageData.Module}
            SelectModuleEN={languageData.SelectModule}
            DMAEN={languageData.DMA}
            HLEN={languageData.HL}
            DDEN={languageData.DD}
            CancelButtonEN={languageData.CancelButton}
            DoneButtonEN={languageData.DoneButton}
            page={languageData.AddModulePopup}
            callApi={createModule}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectHome;