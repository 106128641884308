import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_PROJECT_DETAILS_URL,
  UPDATE_PROJECT_DETAILS_URL,
  GET_USERS_URL,
  PROJECT_BASE_URL,
  CREATE_NEW_USER_URL,
  CREATE_SESSION_URL,
  CREATE_MODULE_URL,
  UPDATE_USER_URL,
  DELETE_USER_URL,
  RETIRE_PROJECT_URL,
} from "../../services/Api";

// Async thunk for fetching project details
export const fetchProjectDetails = createAsyncThunk(
  "fetchProjectDetails",
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${GET_PROJECT_DETAILS_URL}?id=${projectId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching project details:", error);
      return rejectWithValue(
        error.response?.data || "Error fetching project details"
      );
    }
  }
);

// Async thunk for updating project details
export const updateProjectDetails = createAsyncThunk(
  "updateProjectDetails",
  async (projectData, { rejectWithValue }) => {
    try {
      const response = await axios.put(UPDATE_PROJECT_DETAILS_URL, projectData);
      return response.data;
    } catch (error) {
      console.error("Error updating project details:", error);
      return rejectWithValue(
        error.response?.data || "Error updating project details"
      );
    }
  }
);

// Async thunk for creating session
export const createSessionInProject = createAsyncThunk(
  "createSessionInProject",
  async (sessionData, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_SESSION_URL, sessionData);
      return response.data;
    } catch (error) {
      console.error("Error creating session data:", error);
      return rejectWithValue(
        error.response?.data || "Error creating session data"
      );
    }
  }
);

// Async thunk for creating module
export const createModuleInProject = createAsyncThunk(
  "createModuleInProject",
  async (moduleData, { rejectWithValue }) => {
    try {
      const response = await axios.post(CREATE_MODULE_URL, moduleData);
      return response.data;
    } catch (error) {
      console.error("Error creating module data:", error);
      return rejectWithValue(
        error.response?.data || "Error creating module data"
      );
    }
  }
);

// Async thunk for fetching users
export const fetchUsers = createAsyncThunk(
  "fetchUsers",
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${PROJECT_BASE_URL}${projectId}${GET_USERS_URL}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching users list:", error);
      return rejectWithValue(
        error.response?.data || "Error fetching users list"
      );
    }
  }
);

// Async thunk for posting New User admin
export const createNewUser = createAsyncThunk(
  "createNewUser",
  async ({ projectId, userData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${PROJECT_BASE_URL}${projectId}${CREATE_NEW_USER_URL}`,
        userData
      );
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      return rejectWithValue(error.response?.data || "Error creating user");
    }
  }
);

// Async thunk for updating user
export const updateUser = createAsyncThunk(
  'updateUser',
  async ({userData}, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${UPDATE_USER_URL}`,userData);
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      return rejectWithValue(error.response?.data || "Error updating user");
    }
  }
);

// Async thunk for deleting user
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async ({ projectId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${PROJECT_BASE_URL}${projectId}${DELETE_USER_URL}?userId=${userId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting user:", error);
      return rejectWithValue(error.response?.data || "Error deleting user");
    }
  }
);

// Async thunk for retire project
export const retireProject = createAsyncThunk(
  "retireProject",
  async (projectData, { rejectWithValue }) => {
    try {
      const response = await axios.put(RETIRE_PROJECT_URL, projectData);
      return response.data;
    } catch (error) {
      console.error("Error retire project:", error);
      return rejectWithValue(error.response?.data || "Error retire project");
    }
  }
);

// Slice definition
const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    errorMessage: "",
    updateSuccess: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectDetails.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchProjectDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchProjectDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateProjectDetails.pending, (state) => {
        state.isLoading = true;
        state.updateSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateProjectDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateProjectDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.updateSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createNewUser.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createNewUser.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createSessionInProject.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createSessionInProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createSessionInProject.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createModuleInProject.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createModuleInProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(createModuleInProject.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(retireProject.pending, (state) => {
        state.isLoading = true;
        state.postSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(retireProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postSuccess = true;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(retireProject.rejected, (state, action) => {
        state.isLoading = false;
        state.postSuccess = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default projectDetailsSlice.reducer;
