import React, { useState, useEffect } from "react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit, Delete } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import SourceValue from "../../../../components/modals/dmaModals/SourcesValue";
import { useDispatch, useSelector } from "react-redux";
import languageEN from "../../../../constants/languages/en/translations.json";
import {
  fetchAps,
  fetchApsTypes,
  createAps,
  updateAps,
  deleteAps,
} from "../../../../features/slices/DMAAps";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import Pagination from "../../../../components/paginationForCards/Pagination";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import TopBar from "../Topbar";
import "./APS.scss";

const Aps = () => {
  const [isApsTableView, setIsApsTableView] = useState(true);
  const [isApsModalOpen, setIsApsModalOpen] = useState(false);
  const [modalApsMode, setModalApsMode] = useState("add");
  const [modalApsTitle, setModalApsTitle] = useState("");
  const [selectedApsRow, setSelectedApsRow] = useState(null);
  const [currentApsPage, setCurrentApsPage] = useState(1);
  const [searchApsQuery, setSearchApsQuery] = useState("");
  const [isApsDeleteModalOpen, setIsApsDeleteModalOpen] = useState(false);
  const [apsToDelete, setApsToDelete] = useState(null);
  const [types, setTypes] = useState([]);
  const { moduleId } = useParams();
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAps(moduleId));
    dispatch(fetchApsTypes()).then((response) => {
      if (response?.payload?.data) {
        setTypes(response.payload.data);
      }
    });
  }, [dispatch, moduleId]);
  const {
    data: responseData,
    isLoading,
    isError,
  } = useSelector((state) => state.dmaAps);

  useEffect(() => {
    if (responseData?.data) {
      setApsData(responseData.data);
    }
  }, [responseData]);

  const [apsData, setApsData] = useState([]);

  const handleEdit = (row) => {
    setSelectedApsRow(row);
    setModalApsMode("edit");
    setModalApsTitle("Edit activity, product or services");
    setIsApsModalOpen(true);
  };

  const handleDelete = async () => {
    if (!apsToDelete) return;

    try {
      const response = await dispatch(deleteAps({ apsId: apsToDelete.id }));
      if (!response.error) {
        setApsData((prevData) => {
          if (Array.isArray(prevData)) {
            return prevData.filter((aps) => aps.id !== apsToDelete.id);
          } else {
            return prevData;
          }
        });
        dispatch(fetchAps(moduleId));
      } else {
        console.error("Error deleting APS:", response.error);
      }
    } catch (error) {
      console.error("Error deleting APS:", error);
    } finally {
      setIsApsDeleteModalOpen(false); // Close the delete confirmation modal
    }
  };

  const openDeleteModal = (row) => {
    setApsToDelete(row);
    setIsApsDeleteModalOpen(true);
  };

  const handleSourceValueClick = async (apsData) => {
    try {
      let response;
      if (modalApsMode === "add") {
        response = await dispatch(createAps({ moduleId, apsData }));
        if (response.payload) {
          setApsData((prevData) => [...prevData, response.payload]);
          handleCloseModal();
          dispatch(fetchAps(moduleId));
        } else {
          console.error("Error creating APS:", response.error);
        }
      } else if (modalApsMode === "edit" && selectedApsRow) {
        response = await dispatch(
          updateAps({ apsId: selectedApsRow.id, apsData })
        );
        if (response.payload) {
          setApsData((prevData) =>
            Array.isArray(prevData)
              ? prevData.map((aps) =>
                  aps.id === response.payload.id ? response.payload : aps
                )
              : [response.payload]
          );
          handleCloseModal();
          dispatch(fetchAps(moduleId));
        } else {
          console.error("Error updating APS:", response.error);
        }
      }
      handleCloseModal();
      dispatch(fetchAps(moduleId));
    } catch (error) {
      console.error("Error creating/updating APS:", error);
    }
  };

  const renderApsTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ textAlign: "right", paddingRight: "2.55%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => openDeleteModal(row)}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    if (colName === "actProServiceType.name") {
      return <TableCell key={index}>{row.actProServiceType.name}</TableCell>;
    }
    return (
      <TableCell key={index} className="truncate">
        {value}
      </TableCell>
    );
  };

  const generateRowKey = (row) => row.id;

  const handleOpenModal = (mode) => {
    setModalApsMode(mode);
    setSelectedApsRow(null);
    if (mode === "add") {
      setModalApsTitle("New activity, product or services");
    } else if (mode === "edit") {
      setModalApsTitle("Edit activity, product or services");
    }
    setIsApsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsApsModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentApsPage(page);
  };

  const handleSearch = (event) => {
    setSearchApsQuery(String(event));
  };

  const getApsFilteredData = () => {
    return Array.isArray(apsData)
      ? apsData.filter((item) =>
          item.name?.toLowerCase().includes(searchApsQuery.toLowerCase())
        )
      : [];
  };

  const paginatedData = getApsFilteredData().slice(
    (currentApsPage - 1) * itemsPerPage,
    currentApsPage * itemsPerPage
  );

  if (isError) {
    return <div>Error loading data.</div>;
  }

  const columnsAps = [
    { colName: "name", label: "Name", showSorting: true },
    { colName: "actProServiceType.name", label: "Type", showSorting: true },
    { colName: "description", label: "Description", showSorting: true },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  const totalPages = Math.ceil(getApsFilteredData().length / itemsPerPage);

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleOpenModal("add")}
        onChangeSearchInput={handleSearch}
      />
      <TopBar
        isTableView={isApsTableView}
        totalResults={apsData.length}
        filteredResults={getApsFilteredData().length}
        onTableViewClick={() => setIsApsTableView(true)}
        onCardViewClick={() => setIsApsTableView(false)}
      />
      <div className="gl-card-table-switch">
        {isApsTableView ? (
          <ClientTable
            columns={columnsAps}
            data={getApsFilteredData()}
            itemsPerPage={5}
            renderTableCell={(colName, value, row, index) => {
              return renderApsTableCell(colName, value, row, index);
            }}
            generateRowKey={generateRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                pageName="APS"
                stakeHolderType={item.actProServiceType.name}
                descriptionHeaderText={item.description}
                handleEdit={() => handleEdit(item)}
                handleDelete={() => openDeleteModal(item)}
              />
            ))}
          </div>
        )}
      </div>
      {!isApsTableView && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentApsPage}
          onPageChange={handlePageChange}
        />
      )}
      {isApsModalOpen && (
        <SourceValue
          onClose={handleCloseModal}
          setName={modalApsTitle}
          handleStatusChange={() => {}}
          handleSourceValueClick={handleSourceValueClick}
          modalMode={modalApsMode}
          NameLabel={languageEN.Name}
          TypeLabel={languageEN.Type}
          statusActiveLabel={languageEN.Upstream}
          statusInactiveLabel={languageEN.DownStream}
          DescriptionLabel={languageEN.Description}
          cancelButtonLabel={languageEN.Cancel}
          saveButtonLabel={languageEN.AddToList}
          updateButtonLabel={languageEN.Update}
          pageName="APS"
          types={types}
          selectedRow={selectedApsRow}
        />
      )}
      {isApsDeleteModalOpen && (
        <DeleteModal
          isOpen={isApsDeleteModalOpen}
          onClose={() => setIsApsDeleteModalOpen(false)}
          setName={languageEN.DeleteLabel}
          labelText={languageEN.DeletConfirmationSources}
          onCancel={() => setIsApsDeleteModalOpen(false)}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={languageEN.Cancel}
          deleteButtonLabel={languageEN.DeleteLabel}
        />
      )}
    </div>
  );
};

export default Aps;
