import React, { useState, useEffect, useRef, useMemo, Suspense } from "react";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./UserManagement.scss";
import Header from "../../components/headers/Header";
import Subheader from "../../components/subheader/Subheader";
import AddUser from "../../components/modals/addUser/AddUser";
import { TableCell, Box, IconButton } from "@mui/material";
import { Edit, Delete } from "../../constants/icons/Icons";
import language from "../../constants/languages/en/translations.json";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  createNewUser,
  updateUser,
  deleteUser,
} from "../../features/slices/ProjectScreens";
import { useParams } from "react-router-dom";

const ClientTable = React.lazy(() =>
  import("../../components/clientTable/ClientTable")
);

const UserManagement = () => {
  const isAdmin = true;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const userIDsRef = useRef([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [modalType, setModalType] = useState("");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [transformedData, setTransformedData] = useState([]);
  const ProjectAdmin =language.ProjectAdmin;
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await dispatch(fetchUsers(projectId)).unwrap();
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };
    fetchUserData();
  }, [dispatch, projectId, updateTrigger]);

  const { data: responseData = { data: [] }, isLoading } = useSelector(
    (state) => state.projectDetails
  );

  useEffect(() => {
    if (Array.isArray(responseData?.data)) {
      userIDsRef.current = responseData.data.map((item) => item.id);
    }
  }, [responseData]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setModalType(language.UserManagementPage);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleUpdateOpenModal = (userId, role, name, email) => {
    const admins = responseData?.data?.filter(
      (user) => user.role.name === ProjectAdmin
    );
    if (role.toLocaleLowerCase() ===ProjectAdmin.toLocaleLowerCase() && admins?.length <= 1) {
      alert("You cannot update last Project Admin.");
      return;
    }

    if (
      role !== "Project Admin" &&
      !(
        email.trim().toLowerCase().endsWith("@ey.com") ||
        email.trim().toLowerCase().endsWith(".ey.com")
      )
    ) {
      alert("User does not have permission to update the role.");
      return;
    }

    setIsUpdateModalOpen(true);
    setModalType(language.UpdateUserManagementPage);
    setSelectedUserId(userId);
    setRole(role);
    setEmail(email);
    setUserName(name);
  };

  const handleUpdateCloseModal = () => {
    setIsUpdateModalOpen(false);
  };

  const handleDeleteOpenModal = (userId, role) => {
  
    const admins = responseData?.data?.filter(
      (user) => user.role.name.toLowerCase() === ProjectAdmin.toLowerCase()
    );
   
    if (role.toLocaleLowerCase() === ProjectAdmin.toLocaleLowerCase() && admins?.length <= 1) {
      alert("There must be at least one Project Admin.");
      return;
    }
    setIsDeleteModalOpen(true);
    setSelectedUserId(userId);
    setModalType(language.ConfirmationPopup);
  };

  const handleDeleteCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteConfirm = async () => {
    await dispatch(deleteUser({ projectId, userId: selectedUserId }))
      .unwrap()
      .then(() => {
        setUpdateTrigger((prev) => prev + 1);
        handleDeleteCloseModal();
        const fetchUserData = async () => {
          try {
            await dispatch(fetchUsers(projectId)).unwrap();
          } catch (error) {
            console.error("Failed to fetch users:", error);
          }
        };
        fetchUserData();
      })
      .catch((error) => {
        console.error("Deletion failed:", error);
        alert("Failed to delete user: " + error.message);
      });
  };

  const handleButtonClick = (row, buttonName) => {
    switch (buttonName) {
      case "Edit":
        handleUpdateOpenModal(row.userId, row.Role, row.Name, row.Email);
        break;
      case "Delete":
        handleDeleteOpenModal(row.userId, row.Role);
        break;
      default:
    }
  };

  const columnsToDisplay = useMemo(
    () => [
      { colName: "Name", label: "Name", showSorting: true },
      { colName: "Role", label: "Role", showSorting: true },
      { colName: "Email", label: "Email", showSorting: true },
      { colName: "Action", label: "Action", showSorting: false },
    ],
    []
  );

  useEffect(() => {
    if (Array.isArray(responseData?.data)) {
      const data = responseData.data.map((item) => ({
        userId: item.user?.id,
        Name: item.user?.name,
        Email: item.user?.email,
        Role: item.role?.name,
      }));
      setTransformedData(data);
    }
  }, [responseData]);

  const createNewUserInAdminUser = async (userData) => {
    if (userData.userName && userData.email && userData.roleName) {
      if (
        userData.roleName.toLocaleLowerCase()=== ProjectAdmin.toLocaleLowerCase() &&
        !(
          userData.email.trim().toLowerCase().endsWith("@ey.com") ||
          userData.email.trim().toLowerCase().endsWith(".ey.com")
        )
      ) {
        alert("User not authorized to be Project Admin");
      } else {
        await dispatch(createNewUser({ projectId, userData }))
          .unwrap()
          .then((response) => {
            if (response.success) {
              setUpdateTrigger((prev) => prev + 1);
              handleCloseModal();
              dispatch(fetchUsers(projectId)).unwrap();
            }
          })
          .catch((error) => {
            console.error("Creation failed:", error);
            alert("Failed to create user: " + error.message);
          });
      }
    } else {
      alert("Please fill all the required fields");
    }
  };

  const updateUserInAdminUser = async (userData) => {
    if (userData.newRoleName) {
      userData.userId = selectedUserId;
      userData.projectId = projectId;
      await dispatch(updateUser({ userData }))
        .unwrap()
        .then((response) => {
          if (response) {
            setUpdateTrigger((prev) => prev + 1);
            handleUpdateCloseModal();
            dispatch(fetchUsers(projectId)).unwrap();
          }
        })
        .catch((error) => {
          console.error("Update failed:", error);
          alert("Failed to update user: " + error.message);
        });
    } else {
      alert("Please fill all the fields");
    }
  };

  const generateRowKey = (row) => row.userId;

  const getCellStyle = () => ({ padding: "15px" });

  const renderTableCell = (column, value, row, index) => {
    const cellStyle = getCellStyle();
    if (column === "Action") {
      return (
        <TableCell key={column} style={cellStyle}>
          <Box display="flex" justifyContent="flex-end" paddingRight="10%">
            <IconButton onClick={() => handleButtonClick(row, "Edit")}>
              <Edit />
            </IconButton>
            <IconButton
              color="secondary"
              onClick={() => handleButtonClick(row, "Delete")}
            >
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    return (
      <TableCell key={column} style={cellStyle}>
        {value}
      </TableCell>
    );
  };

  const filteredData = transformedData.filter((row) => {
    const nameValue = String(row.Name) || "";
    const emailValue = String(row.Email) || "";
    const roleValue = String(row.Role) || "";
    return (
      nameValue.toLowerCase().includes(searchQuery.toLowerCase()) ||
      emailValue.toLowerCase().includes(searchQuery.toLowerCase()) ||
      roleValue.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  return (
    <div className="all-user-management-container">
      {isLoading && <MotifProgressLoader show variant="default" />}
      <Header />
      <div className="user-management-org-container">
        <div className="user-management-org-subheader">
          <Subheader
            isAdmin={isAdmin}
            page={language.ClientOrgAccessTitle}
            title={language.UserManagementTitle}
            onChangeSearchInput={handleSearch}
            handleOpenModal={handleOpenModal}
            AddNewEN={language.AddNew}
            ClientOrgAccessTitleEN={language.ClientOrgAccessTitle}
            CreateNewEN={language.AddNew}
          />
          {isLoading && <MotifProgressLoader show variant="default" />}
          {modalType === language.UserManagementPage && (
            <AddUser
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              page={language.UserManagementPage}
              TitleUserManagementEN={language.TitleUserManagement}
              UserNameEN={language.UserName}
              UserNameContentEN={language.UserNameContent}
              RoleEN={language.Role}
              SelectRoleEN={language.SelectRole}
              UserEmailEN={language.UserEmail}
              EmailContentEN={language.EmailContent}
              CancelButtonEN={language.CancelButton}
              DoneButtonEN={language.DoneButton}
              UserManagementPageEN={language.UserManagementPage}
              SelectRole1EN={language.SelectRole1}
              SelectRole2EN={language.SelectRole2}
              callApi={createNewUserInAdminUser}
            />
          )}
          {isLoading && <MotifProgressLoader show variant="default" />}
          {modalType === language.UpdateUserManagementPage && (
            <AddUser
              isOpen={isUpdateModalOpen}
              onClose={handleUpdateCloseModal}
              page={language.UpdateUserManagementPage}
              TitleUserManagementEN={language.TitleUpdateUserManagement}
              UserNameEN={language.UserName}
              UserNameContentEN={language.UserNameContent}
              RoleEN={language.Role}
              SelectRoleEN={language.SelectRole}
              UserEmailEN={language.UserEmail}
              EmailContentEN={language.EmailContent}
              CancelButtonEN={language.CancelButton}
              UpdateButtonEN={language.UpdateButton}
              TitleUpdateUserManagementEN={
                language.TitleUpdateUserManagement
              }
              SelectRole1EN={language.SelectRole1}
              SelectRole2EN={language.SelectRole2}
              UpdateUserManagementPageEN={
                language.UpdateUserManagementPage
              }
              selectedUsername={userName}
              selectedRole={role}
              selectedEmail={email}
              callApi={updateUserInAdminUser}
            />
          )}
          {isLoading && <MotifProgressLoader show variant="default" />}
          {modalType === language.ConfirmationPopup && (
            <AddUser
              isOpen={isDeleteModalOpen}
              onClose={handleDeleteCloseModal}
              page={language.ConfirmationPopup}
              ConfirmationPopup={language.ConfirmationPopup}
              ConfirmationTitle={language.ConfirmationTitle}
              ConfirmationEN={language.Confirmation}
              ConfirmationDeleteMsgEN={language.ConfirmationDeleteMsg}
              CancelButtonEN={language.CancelButton}
              callApi={handleDeleteConfirm}
            />
          )}
        </div>
        <div>
          <Suspense
            fallback={
              <MotifProgressLoader
                data-testid="loading-spinner"
                show
                variant="default"
              />
            }
          >
            <ClientTable
              columns={columnsToDisplay}
              data={filteredData}
              itemsPerPage={5}
              section={language.InstanceHomeProjectsEN}
              generateRowKey={generateRowKey}
              getCellStyle={getCellStyle}
              renderTableCell={renderTableCell}
              handleButtonClick={handleButtonClick}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
