import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import DMACards from "./dmaCards/DMACards";
import langauge from "../../../constants/languages/en/translations.json";
import IROData from "./DMAIROData.json";
import { Stake,Context, Iro, Reporting,PrivacyNote } from "../../../constants/icons/Icons";
import { useSelector, useDispatch } from "react-redux";
import { fetchUnderstandingTheContextCount } from "../../../features/slices/DMAOverview";

export const DMAOverview = () => {
  const dispatch = useDispatch();
  const { moduleId } = useParams();

  useEffect(() => {
    dispatch(fetchUnderstandingTheContextCount(moduleId));
  }, [dispatch, moduleId]);

  const { data: responseData, isLoading } = useSelector(
    (state) => state.dmaOverview
  );

  return (
    <div className="dma-overview-container">
      {isLoading && <MotifProgressLoader show variant="default" />}
      <DMACards
        headerIconLeft={<Stake />}
        headerIconRight={<Context />}
        lefttype={langauge.UTC}
        UTC={langauge.UTC}
        references={responseData?.data}
        rightHeaderType={langauge.StakeholderOutreach}
        StakeholderOutreach={langauge.StakeholderOutreach}
      />
      <DMACards
        headerIconLeft={<Iro />}
        headerIconRight={<Reporting />}
        Longlist={langauge.Longlist}
        Shortlist={langauge.Shortlist}
        Dashboard={langauge.Dashboard}
        references={IROData}
        ReportingImplication={langauge.ReportingImplication}
        lefttype={langauge.IROIdentificationAndAssessment}
        IROIdentificationAndAssessment={langauge.IROIdentificationAndAssessment}
        rightHeaderType={langauge.ReportingImplication}
      />
    </div>
  );
};

export default DMAOverview;
