import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_ALL_PROJECTS } from "../../services/Api";

export const fetchAllProjects = createAsyncThunk(
  "getAllProjects",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_PROJECTS);
      return response.data;
    } catch (error) {
      console.error("Error fetching projects:", error);
      return rejectWithValue(error.response?.data || "Error fetching projects");
    }
  }
);
const fetchAllProjectsSlice = createSlice({
  name: "allProjects",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProjects.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllProjects.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAllProjects.rejected, (state, action) => {
      console.error("error", action.payload);
      state.isError = true;
    });
  },
});

export default fetchAllProjectsSlice.reducer;
