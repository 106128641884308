import React, { useState, useEffect } from "react";
import ThresholdCard from "../../../../components/thresholdcard/ThresholdCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchThreshold,
  createUpdateThreshold,
} from "../../../../features/slices/DMAProcessSpecificationThreshold";
import { useParams } from "react-router-dom";
import language from "../../../../constants/languages/en/translations.json";
import "./Threshold.scss";
const Thresholds = () => {
  const [thresholdImpact, setThresholdImpact] = useState(0);
  const [thresholdFinancial, setThresholdFinancial] = useState(0);

  const { moduleId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchThreshold({ moduleId, type: "Impact" }));
  }, [dispatch, moduleId]);

  const { data: responseDataImpact, isLoadingImpact } = useSelector(
    (state) => state.dmaProcessSpecification || { data: null, isLoading: false }
  );

  useEffect(() => {
    if (responseDataImpact?.data) {
      setThresholdImpact(responseDataImpact?.thresholds);
    }
  }, [responseDataImpact]);

  useEffect(() => {
    dispatch(fetchThreshold({ moduleId, type: "Financial" }));
  }, [dispatch, moduleId]);

  const { data: responseDataFinancial, isLoadingFinancial } = useSelector(
    (state) => state.dmaProcessSpecification || { data: null, isLoading: false }
  );

  useEffect(() => {
    if (responseDataFinancial?.data) {
      setThresholdFinancial(responseDataFinancial?.thresholds);
    }
  }, [responseDataFinancial]);

  const updateThreshold = (thresholdData) => {
    if (thresholdData) {
      dispatch(createUpdateThreshold({ moduleId, thresholdData }))
        .unwrap()
        .then((response) => {
        })
        .catch((error) => {
          console.error("Update failed:", error);
          alert("Failed to update threshold: " + error);
        });
    } else {
      alert("Please select the threshold value");
    }
  };

  return (
    <div className="threshold-container">
      <ThresholdCard
        type={"Impact"}
        sliderHeader={language.ImpactHeaderSlider}
        sliderDescription={language.ImpactDescription}
        thresholdToInput={thresholdImpact}
        updateThreshold={updateThreshold}
      />

      <ThresholdCard
        type={"Financial"}
        sliderHeader={language.FinancialHeaderSlider}
        sliderDescription={language.FinancialDescription}
        thresholdToInput={thresholdFinancial}
        updateThreshold={updateThreshold}
      />
    </div>
  );
};

export default Thresholds;
