import React, { useReducer, useCallback, useMemo } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
} from "@mui/material";
import { Sort } from "../../constants/icons/Icons";
import PropTypes from "prop-types";
import "./ClientTable.scss";

const ClientTable = ({
  columns,
  data,
  itemsPerPage,
  renderTableCell,
  generateRowKey,
}) => {
  const initialState = {
    page: 0,
    sortConfig: { key: null, direction: "asc" },
    rowsPerPage: itemsPerPage,
    searchQuery: "",
  };

  const actionTypes = {
    SET_PAGE: "SET_PAGE",
    SET_SORT_CONFIG: "SET_SORT_CONFIG",
    SET_ROWS_PER_PAGE: "SET_ROWS_PER_PAGE",
    SET_SEARCH_QUERY: "SET_SEARCH_QUERY",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case actionTypes.SET_PAGE:
        return { ...state, page: action.payload };
      case actionTypes.SET_SORT_CONFIG:
        return { ...state, sortConfig: action.payload };
      case actionTypes.SET_ROWS_PER_PAGE:
        return { ...state, rowsPerPage: action.payload, page: 0 };
      case actionTypes.SET_SEARCH_QUERY:
        return { ...state, searchQuery: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const filterAndSortData = useCallback(() => {
    if (!data) return [];
    const dataArray = Object.keys(data).map((projectId) => ({
      id: projectId,
      ...data[projectId],
    }));
    let filteredData = [...dataArray];
    if (state.searchQuery) {
      filteredData = filteredData.filter((item) =>
        item?.projectName?.toLowerCase().includes(state.searchQuery.toLowerCase())
      );
    }
    if (state.sortConfig.key) {
      filteredData.sort((a, b) => {
        if (a[state.sortConfig.key] < b[state.sortConfig.key]) {
          return state.sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[state.sortConfig.key] > b[state.sortConfig.key]) {
          return state.sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredData;
  }, [data, state.searchQuery, state.sortConfig]);

  const displayedData = useMemo(() => filterAndSortData(), [filterAndSortData]);

  const handleChangePage = (event, newPage) => {
    dispatch({ type: actionTypes.SET_PAGE, payload: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: actionTypes.SET_ROWS_PER_PAGE,
      payload: parseInt(event.target.value, 10),
    });
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (state.sortConfig.key === key && state.sortConfig.direction === "asc") {
      direction = "desc";
    }
    dispatch({ type: actionTypes.SET_SORT_CONFIG, payload: { key, direction } });
  };

  return (
    <Box width="100%" fontFamily="EYInterstate">
      <Box border={1} borderColor="grey.300">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.colName}
                  className="table-header-cell"
                  style={{
                    flex: 1,
                    ...(index === columns.length - 1 && {
                      textAlign: "right",
                    }),
                    paddingRight: index === columns.length - 1 ? "6.5%" : "0",
                  }}
                  onClick={() => requestSort(column.colName)}
                >
                  {column.label}
                  {column.showSorting && <span className="sort-icon"><Sort /></span>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData
              .slice(state.page * state.rowsPerPage, (state.page + 1) * state.rowsPerPage)
              .map((row) => (
                <TableRow key={generateRowKey(row)}>
                  {columns.map((column, index) =>
                    renderTableCell(column.colName, row[column.colName], row, index)
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={displayedData.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

ClientTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  renderTableCell: PropTypes.func.isRequired,
  generateRowKey: PropTypes.func.isRequired,
};

export default ClientTable;
