import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Stepper.scss";

const Stepper = ({ children, onChange }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (onChange) {
      onChange(activeIndex);
    }
  }, [activeIndex, onChange]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <div className="custom-stepper stepper-background" role="tablist">
        {React.Children.map(children, (child, index) => {
          const key = child.key || index;
          return (
            <button
              key={key}
              className={`stepper-box-item ${activeIndex === index ? "active" : ""}`}
              onClick={() => handleItemClick(index)}
              data-testid={`box-item-${index}`}
              role="tab"
              aria-selected={activeIndex === index}
              aria-controls={`step-${index}`}
            >
              <div className="stepper-content">
                <div className="stepper-icon">{child.props.icon}</div>
                <div className="stepper-text">{child.props.text}</div>
              </div>
              {index < children.length - 1 && <div className="line"></div>}
            </button>
          );
        })}
      </div>
      <div
        className="stepper-section-content"
        data-testid="section-content"
        role="tabpanel"
        id={`step-${activeIndex}`}
      >
        {React.Children.toArray(children)[activeIndex].props.children}
      </div>
    </>
  );
};

Stepper.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
};

Stepper.defaultProps = {
  onChange: () => {},
};

export default Stepper;
