// DmaContext.jsx
import React from "react";
import ContextSubHeader from "../../../components/contextSubHeader/ContextSubHeader";
import ContextStep from "../../../components/contextStep/ContextStep";
import Stakeholders from "./stakeHolders/Stakeholders";
import ValueChain from "./valueChain/ValueChain";
import Sources from "./sources/Sources";
import GeographicalLocation from "./geographicalLocation/GeographicalLocation";
import Aps from "./aps/APS";
import SustainabillityMatters from "./sustainabillityMatters/SustainabillityMatters";
const DmaContext = () => {
  return (
    <div>
      <ContextSubHeader>
        <ContextStep key="sources" text="Sources">
          <Sources />
        </ContextStep>
        <ContextStep key="value-chain" text="Value chain">
          <ValueChain />
        </ContextStep>
        <ContextStep
          key="activities-product-and-services"
          text="Activities, product and services"
        >
          <Aps />
        </ContextStep>
        <ContextStep key="geographical-location" text="Geographical location">
          <GeographicalLocation />
        </ContextStep>
        <ContextStep key="stakeholders" text="Stakeholders">
          <Stakeholders />
        </ContextStep>
        <ContextStep key="sustainability-matters" text="Sustainability matters">
          <SustainabillityMatters />
        </ContextStep>
      </ContextSubHeader>
    </div>
  );
};

export default DmaContext;
