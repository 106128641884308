import React from "react";
import PropTypes from "prop-types";
import "./DMACards.scss";

const DMACards = ({
  headerIconLeft,
  headerIconRight,
  rightHeaderType,
  lefttype,
  Longlist,
  Shortlist,
  Dashboard,
  references,
  UTC,
  IROIdentificationAndAssessment,
  ReportingImplication,
  StakeholderOutreach
}) => {
  return (
    <div className="dma-overview-card">
      <div className="dma-understanding-div">
        <label className="dma-overview-header">
          {headerIconLeft}&nbsp;
          {lefttype === UTC && UTC}
          {lefttype === IROIdentificationAndAssessment && IROIdentificationAndAssessment}
        </label>
        <div className="dma-overview-content-div">
          {references?.map((reference) => (
            <div key={reference.id} className="dma-overview-row">
              <span className="dma-overview-number-of-reference">
                {reference.count}
              </span>
              <label className="dma-overview-content">
                {reference.label}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="dma-stakeholder-div">
        <label className="dma-overview-header">
          {headerIconRight}&nbsp;
          {rightHeaderType === StakeholderOutreach && StakeholderOutreach}
          {rightHeaderType === ReportingImplication && ReportingImplication}
        </label>
        {rightHeaderType === ReportingImplication && (
          <div className="dma-overview-list">
            <div className="dma-reporting-list">
              <label className="dma-reporting-header">{Longlist}</label>
            </div>
            <div className="dma-reporting-list">
              <label className="dma-reporting-header">{Shortlist}</label>
            </div>
            <div className="dma-reporting-list">
              <label className="dma-reporting-header">{Dashboard}</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DMACards.propTypes = {
  headerIconLeft: PropTypes.object,
  headerIconRight: PropTypes.object,
  Longlist: PropTypes.string,
  Shortlist: PropTypes.string,
  Dashboard: PropTypes.string,
  references: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  rightHeaderType: PropTypes.string,
  lefttype: PropTypes.string,
  UTC: PropTypes.string,
  IROIdentificationAndAssessment: PropTypes.string,
  ReportingImplication: PropTypes.string,
  StakeholderOutreach: PropTypes.string,
};

export default DMACards;
