import React, { useState, useEffect } from "react";
import "./NewProject.scss";
import {
  MotifCard,
  MotifCardBody,
  MotifCardFooter,
  MotifCardHeader,
  MotifButton,
} from "@ey-xd/motif-react";
import Header from "../../components/headers/Header";
import NewProjectForm from "../../components/forms/NewProjectForm";
import { newProjectFormFields } from "./NewProjectData";
import languageData from '../../constants/languages/en/translations.json';
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllUsers
} from "../../features/slices/UsersData";
import { Back } from "../../constants/icons/Icons";
import { useNavigate } from "react-router-dom";

const NewProject = (props) => {
  const [formState, setFormState] = useState({
    projectName: "",
    clientName: "",
    engagementCode: "",
    country: "",
    channel: "",
    projectSize: "",
    clientSector: "",
    projectAdmin: [],
  });
 
  const dispatch = useDispatch();
 
  const {data : allUsers} = useSelector(state => state.allUsers);
 
  const [formFields, setFormFields] = useState(newProjectFormFields);
 
  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);
 
  useEffect(() => {
    if (allUsers?.data) {
      const updatedFields = formFields.map(field => {
        if (field.id === "projectAdmin") {
          return {
            ...field,
            options: allUsers.data.map(user => ({
              value: user.id,
              label: user.userName
            }))
          };
        }
        return field;
      });
      setFormFields(updatedFields);
    }
  }, [allUsers, formFields]);
 
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };

  return (
    <div>
      <Header />
      <div className="Container">
        <div id="inline">
          <MotifButton onClick={handleBack} className="button done-button">
            {" "}
            <Back />
            &nbsp;
            {languageData.backButton}
          </MotifButton>
        </div>
 
        <MotifCard
          orientation="horizontal"
          variant="card-border"
          className="new-project-card"
        >
          <MotifCardHeader>
            <span className="motif-h6-default-regular">
              {languageData.newProject}
            </span>
          </MotifCardHeader>
          <MotifCardBody>
            <NewProjectForm
              formFields={formFields}
              formState={formState}
              setFormState={setFormState}
            />
          </MotifCardBody>
          <MotifCardFooter className="new-project-footer">
            <MotifButton className="button cancel-button">
              {languageData.cancelButton}
            </MotifButton>
            <MotifButton className="button done-button">
              {languageData.submitButton}
            </MotifButton>
          </MotifCardFooter>
        </MotifCard>
      </div>
    </div>
  );
};
 
export default NewProject;
 