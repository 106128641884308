import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifMessage,
  MotifRadioButton,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./SustainabilityMatters.scss";

const SustainabilityMattersModal = ({
  onClose,
  modalMode,
  modalTitle,
  topicNameLabel,
  parentLabel,
  esrsLabel,
  esrsAr16Label,
  esgLabel,
  enterTopicNameLabel,
  selectParentLabel,
  selectEsrsLabel,
  selectEsrsAr16Label,
  DescriptionLabel,
  saveButtonLabel,
  updateButtonLabel,
  cancelButtonLabel,
  esrsOptions,
  esrsar16Options,
  parentOptions,
  handleDoneClick,
  filteredUpdateData,
}) => {
  const isEditMode = modalMode === "edit";
  const [selectedTab, setSelectedTab] = useState("Definition");
  const [topicName, setTopicName] = useState("");
  const [parent, setParent] = useState("");
  const [esrs, setEsrs] = useState("");
  const [esrsAr16, setEsrsAr16] = useState("");
  const [esg, setEsg] = useState("E");
  const [description, setDescription] = useState("");
  const [esrsUpdateValue, setEsrsUpdateValue] = useState("");
  const [parentUpdateValue, setParentUpdateValue] = useState("");
  useEffect(() => {
    if (filteredUpdateData && isEditMode) {
      setTopicName(filteredUpdateData.label || "");
      setParent(filteredUpdateData.parentId || "");
      setEsrs(parseFloat(filteredUpdateData.frameworkReference.id) || 0);
      setEsrsAr16(parseFloat(filteredUpdateData.esrsaR16.id) || 0);
      setDescription(filteredUpdateData.description);
      setEsg(filteredUpdateData.esg);
    }
  }, [filteredUpdateData, isEditMode]);

  useEffect(() => {
    const parentUpdateValue = parentOptions.filter(
      (item) => item.value === parent
    );
    const esrsUpdateValue = esrsOptions.filter((item) => item.value === esrs);
    setEsrsUpdateValue(esrsUpdateValue[0]?.label || "NA");
    setParentUpdateValue(parentUpdateValue[0]?.label || "NA");
  }, [parent, esrs, esrsOptions, parentOptions]);

  const handleSubmit = () => {
    if (!esrsAr16 || !esrs || !topicName) {
      alert("Please fill all the details in the basic tab");
    } else {
      const data = {
        sustainabilityMatterId: 1100000,
        parentId: parent || null,
        esrsaR16Id: esrsAr16,
        frameworkReferenceId: esrs,
        name: topicName,
        esg: esg,
        description: description || "",
        aggregatedGroupName: "string",
        isRelevant: true,
        explanationOfRelevance: "string",
        sortOrder: 0,
      };
      handleDoneClick(data);
      onClose();
    }
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "Definition":
        return (
          <>
            {modalMode === "edit" && (
              <div className="form-row definition-section">
                <MotifLabel className="definition-main-text">
                  <strong>{esrsUpdateValue}</strong> | {topicName} |{" "}
                  {parentUpdateValue}
                </MotifLabel>
              </div>
            )}
            <div className="form-row sustain-description-label">
              <MotifLabel id="select-label">{DescriptionLabel}</MotifLabel>
              <TextField
                placeholder=""
                multiline
                fullWidth
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </>
        );
      case "Basic":
        return (
          <>
            <div className="form-row">
              <div className="form-group">
                <MotifMessage>{topicNameLabel}</MotifMessage>
                <MotifFormField>
                  <MotifInput
                    placeholder={enterTopicNameLabel}
                    value={topicName}
                    onChange={(e) => setTopicName(e.target.value)}
                  />
                </MotifFormField>
              </div>
              <div className="form-group">
                <MotifMessage>{parentLabel}</MotifMessage>
                <MotifFormField>
                  <MotifLabel>{selectParentLabel}</MotifLabel>
                  <MotifSelect value={parent} onChange={(e) => setParent(e)}>
                    {parentOptions.map((type) => (
                      <MotifOption key={type.value} value={type.value}>
                        {type.label}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                </MotifFormField>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group half-width">
                <MotifMessage>{esrsLabel}</MotifMessage>
                <MotifFormField>
                  <MotifLabel>{selectEsrsLabel}</MotifLabel>
                  <MotifSelect value={esrs} onChange={(e) => setEsrs(e)}>
                    {esrsOptions.map((type) => (
                      <MotifOption key={type.value} value={type.value}>
                        {type.label}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                </MotifFormField>
              </div>
              <div className="form-group half-width">
                <MotifMessage>{esrsAr16Label}</MotifMessage>
                <MotifFormField>
                  <MotifLabel>{selectEsrsAr16Label}</MotifLabel>
                  <MotifSelect
                    value={esrsAr16}
                    onChange={(e) => setEsrsAr16(e)}
                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {esrsar16Options.map((type) => (
                      <MotifOption key={type.value} value={type.value}>
                        {type.label}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                </MotifFormField>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <MotifMessage>{esgLabel}</MotifMessage>
                <div className="radio-group">
                  <MotifFormField>
                    <MotifRadioButton
                      name="esg"
                      onChange={(e) => setEsg(e.target.value)}
                      value="E"
                      checked={esg === "E"}
                    >
                      E
                    </MotifRadioButton>
                    <MotifRadioButton
                      name="esg"
                      onChange={(e) => setEsg(e.target.value)}
                      value="S"
                      className="margin-left-radio"
                      checked={esg === "S"}
                    >
                      S
                    </MotifRadioButton>
                    <MotifRadioButton
                      name="esg"
                      onChange={(e) => setEsg(e.target.value)}
                      value="G"
                      className="margin-left-radio"
                      checked={esg === "G"}
                    >
                      G
                    </MotifRadioButton>
                  </MotifFormField>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-overlay">
      <div className="sustain-modal modal">
        <div className="modal-header">
          <h2>{modalTitle}</h2>
        </div>
        <div className="modal-body">
          <div className="modal-sidebar">
            <ul>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Definition" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Definition")}
                >
                  Definition
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Basic" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Basic")}
                >
                  Basic
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Relevance" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Relevance")}
                >
                  Relevance
                </button>
              </li>
            </ul>
          </div>
          <div className="modal-content">{renderTabContent()}</div>
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {cancelButtonLabel}
          </MotifButton>
          <MotifButton onClick={handleSubmit} className="button done-button">
            {isEditMode ? updateButtonLabel : saveButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

SustainabilityMattersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  topicNameLabel: PropTypes.string.isRequired,
  parentLabel: PropTypes.string.isRequired,
  esrsLabel: PropTypes.string.isRequired,
  esrsAr16Label: PropTypes.string.isRequired,
  esgLabel: PropTypes.string.isRequired,
  enterTopicNameLabel: PropTypes.string.isRequired,
  selectParentLabel: PropTypes.string.isRequired,
  selectEsrsLabel: PropTypes.string.isRequired,
  selectEsrsAr16Label: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  esrsOptions: PropTypes.array,
  esrsar16Options: PropTypes.array,
  parentOptions: PropTypes.array,
  handleDoneClick: PropTypes.func.isRequired,
  filteredUpdateData: PropTypes.object,
};

export default SustainabilityMattersModal;
