import React from "react";
import PropTypes from "prop-types";

const ContextStep = ({ text, children }) => {
  return <>{children}</>;
};

ContextStep.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ContextStep;