import React, { useState, useEffect } from "react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit, Delete } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import SourceValue from "../../../../components/modals/dmaModals/SourcesValue";
import { useDispatch, useSelector } from "react-redux";
import languageEN from "../../../../constants/languages/en/translations.json";
import {
  fetchSources,
  fetchSourcesTypes,
  createSources,
  updateSources,
  deleteSources,
} from "../../../../features/slices/DMASources";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import Pagination from "../../../../components/paginationForCards/Pagination";
import TopBar from "../Topbar";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import "./Sources.scss";

const Sources = () => {
  const [isSourcesTableView, setIsSourcesTableView] = useState(true);
  const [isSourcesModalOpen, setIsSourcesModalOpen] = useState(false);
  const [modalSourcesMode, setModalSourcesMode] = useState("add");
  const [modalSourcesTitle, setModalSourcesTitle] = useState("");
  const [selectedSourcesRow, setSelectedSourcesRow] = useState(null);
  const [currentSourcesPage, setCurrentSourcesPage] = useState(1);
  const [searchSourcesQuery, setSearchSourcesQuery] = useState("");
  const [types, setTypes] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState(null);
  const { moduleId } = useParams();
  const itemsPerPage = 10;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSources(moduleId));
    dispatch(fetchSourcesTypes()).then((response) => {
      if (response?.payload?.data) {
        setTypes(response.payload.data);
      }
    });
  }, [dispatch, moduleId]);

  const {
    data: responseData,
    isLoading,
    isError,
  } = useSelector((state) => state.dmaSources);

  useEffect(() => {
    if (responseData?.data) {
      setSourcesData(responseData.data);
    }
  }, [responseData]);

  const [sourcesData, setSourcesData] = useState([]);

  const handleEdit = (row) => {
    setSelectedSourcesRow(row);
    setModalSourcesMode("edit");
    setModalSourcesTitle("Edit Sources");
    setIsSourcesModalOpen(true);
  };

  const handleDelete = async () => {
    if (!sourceToDelete) return;

    try {
      const response = await dispatch(
        deleteSources({ sourceId: sourceToDelete.id })
      );
      if (!response.error) {
        setSourcesData((prevData) => {
          if (Array.isArray(prevData)) {
            return prevData.filter((source) => source.id !== sourceToDelete.id);
          } else {
            return prevData;
          }
        });
        dispatch(fetchSources(moduleId));
      } else {
        console.error("Error deleting Source:", response.error);
      }
    } catch (error) {
      console.error("Error deleting Source:", error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const openDeleteModal = (row) => {
    setSourceToDelete(row);
    setIsDeleteModalOpen(true);
  };

  const handleSourceValueClick = async (sourcesData) => {
    try {
      let response;
      if (modalSourcesMode === "add") {
        response = await dispatch(
          createSources({ moduleId, sourceData: sourcesData })
        );
        if (response.payload) {
          setSourcesData((prevData) => [...prevData, response.payload]);
          handleCloseModal();
          dispatch(fetchSources(moduleId));
        } else {
          console.error("Error creating source:", response.error);
        }
      } else if (modalSourcesMode === "edit" && selectedSourcesRow) {
        response = await dispatch(
          updateSources({
            sourceId: selectedSourcesRow.id,
            sourceData: sourcesData,
          })
        );
        if (response.payload) {
          setSourcesData((prevData) =>
            Array.isArray(prevData)
              ? prevData.map((source) =>
                  source.id === response.payload.id ? response.payload : source
                )
              : [response.payload]
          );
          handleCloseModal();
          dispatch(fetchSources(moduleId));
        } else {
          console.error("Error updating source:", response.error);
        }
      }
      handleCloseModal();
      dispatch(fetchSources(moduleId));
    } catch (error) {
      console.error("Error creating/updating source:", error);
    }
  };

  const renderSourcesTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ textAlign: "right", paddingRight: "2.55%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleEdit(row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => openDeleteModal(row)}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    if (colName === "sourceType.name") {
      return <TableCell key={index}>{row.sourceType.name}</TableCell>;
    }
    if (colName === "description") {
      return (
        <TableCell key={index} className="truncate">
          {value}
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const generateRowKey = (row) => row.id;

  const handleOpenModal = (mode) => {
    setModalSourcesMode(mode);
    setSelectedSourcesRow(null);
    if (mode === "add") {
      setModalSourcesTitle("New sources");
    } else if (mode === "edit") {
      setModalSourcesTitle("Edit sources");
    }
    setIsSourcesModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSourcesModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentSourcesPage(page);
  };

  const handleSearch = (event) => {
    setSearchSourcesQuery(String(event));
  };

  const getSourcesFilteredData = () => {
    return Array.isArray(sourcesData)
      ? sourcesData.filter((item) =>
          item.name?.toLowerCase().includes(searchSourcesQuery.toLowerCase())
        )
      : [];
  };

  const paginatedData = getSourcesFilteredData().slice(
    (currentSourcesPage - 1) * itemsPerPage,
    currentSourcesPage * itemsPerPage
  );

  if (isError) {
    return <div>Error loading data.</div>;
  }

  const columnsSources = [
    { colName: "name", label: languageEN.Name, showSorting: true },
    { colName: "sourceType.name", label: languageEN.Type, showSorting: true },
    {
      colName: "description",
      label: languageEN.Description,
      showSorting: true,
    },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  const totalPages = Math.ceil(getSourcesFilteredData().length / itemsPerPage);

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleOpenModal("add")}
        onChangeSearchInput={handleSearch}
      />
      <TopBar
        isTableView={isSourcesTableView}
        totalResults={sourcesData.length}
        filteredResults={getSourcesFilteredData().length}
        onTableViewClick={() => setIsSourcesTableView(true)}
        onCardViewClick={() => setIsSourcesTableView(false)}
      />
      <div className="gl-card-table-switch">
        {isSourcesTableView ? (
          <ClientTable
            columns={columnsSources}
            data={getSourcesFilteredData()}
            itemsPerPage={5}
            renderTableCell={(colName, value, row, index) => {
              const cellValue = colName
                .split(".")
                .reduce((acc, part) => acc && acc[part], row);
              return renderSourcesTableCell(colName, cellValue, row, index);
            }}
            generateRowKey={generateRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                pageName="Sources"
                stakeHolderType={item.sourceType.name}
                descriptionHeaderText={item.description}
                handleEdit={() => handleEdit(item)}
                handleDelete={() => openDeleteModal(item)}
              />
            ))}
          </div>
        )}
      </div>
      {!isSourcesTableView && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentSourcesPage}
          onPageChange={handlePageChange}
        />
      )}
      {isSourcesModalOpen && (
        <SourceValue
          onClose={handleCloseModal}
          setName={modalSourcesTitle}
          handleStatusChange={() => {}}
          handleSourceValueClick={handleSourceValueClick}
          modalMode={modalSourcesMode}
          NameLabel={languageEN.Name}
          TypeLabel={languageEN.Type}
          statusActiveLabel={languageEN.Upstream}
          statusInactiveLabel={languageEN.DownStream}
          DescriptionLabel={languageEN.Description}
          cancelButtonLabel={languageEN.Cancel}
          saveButtonLabel={languageEN.AddToList}
          updateButtonLabel={languageEN.Update}
          types={types}
          pageName="Sources"
          selectedRow={selectedSourcesRow}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          setName={languageEN.DeleteLabel}
          labelText={languageEN.DeletConfirmationSources}
          onCancel={() => setIsDeleteModalOpen(false)}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={languageEN.Cancel}
          deleteButtonLabel={languageEN.DeleteLabel}
        />
      )}
    </div>
  );
};

export default Sources;
