import React from 'react';
import { IconButton } from "@mui/material";

export const columns = [
  {colName:"Name",
    showSorting:true
  },
  {colName:"Email",
  showSorting:true
},
{colName:"Type",
showSorting:true
},
{colName:"Action",
showSorting:false
},
 
];


export const data = [
  {
    "Name": "Dikshit .",
    "Email": "dikshit.dikshit@gds.ey.com",
    "Type": "Individual",
    Action: <IconButton />,
  },
  {
    "Name": "Arnab Mazumder",
    "Email": "arnab.mazumder@gds.ey.com",
    "Type": "Function",
    Action: <IconButton />,
  },
  {
    "Name": "Roopa J R",
    "Email": "roopa.jr@gds.ey.com",
    "Type": "Department",
    Action: <IconButton />,
  },
  {
    "Name": "Kiran Sunil Jadhav",
    "Email": "kiran.sunil.jadhav@gds.ey.com",
    "Type": "Department",
    Action: <IconButton />,
  },
  {
    "Name": "Kavya Priya C",
    "Email": "kavya.priya.c@gds.ey.com",
    "Type": "Individual",
    Action: <IconButton />,
  },
  {
    "Name": "Dikshit .",
    "Email": "dikshit.dikshit@gds.ey.com",
    "Type": "Individual",
    Action: <IconButton />,
  },
  {
    "Name": "Arnab Mazumder",
    "Email": "arnab.mazumder@gds.ey.com",
    "Type": "Function",
    Action: <IconButton />,
  },
  {
    "Name": "Roopa J R",
    "Email": "roopa.jr@gds.ey.com",
    "Type": "Department",
    Action: <IconButton />,
  },
  {
    "Name": "Kiran Sunil Jadhav",
    "Email": "kiran.sunil.jadhav@gds.ey.com",
    "Type": "Department",
    Action: <IconButton />,
  },
  {
    "Name": "Kavya Priya C",
    "Email": "kavya.priya.c@gds.ey.com",
    "Type": "Individual",
    Action: <IconButton />,
  },
  
];
