import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const GlModal = ({
  onClose,
  setName,
  handleSourceValueClick,
  modalMode,
  NameLabel,
  DescriptionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  selectedRow,
}) => {
  const [name, setNameInput] = useState("");
  const [description, setDescriptionInput] = useState("");

  useEffect(() => {
    if (modalMode === "edit" && selectedRow) {
      setNameInput(selectedRow.name);
      setDescriptionInput(selectedRow.description);
    } else {
      setNameInput("");
      setDescriptionInput("");
    }
  }, [modalMode, selectedRow]);

  const handleSubmit = () => {
    const glData = {
      name,
      description,
    };
    handleSourceValueClick(glData);
  };

  const isEditMode = modalMode === "edit";

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <MotifLabel>{NameLabel}</MotifLabel>
          <MotifInput
            className="session-modal-input session-name-input"
            aria-describedby="session-name"
            hideClearButton={true}
            value={name}
            onChange={(e) => setNameInput(e.target.value)}
          />

          <MotifLabel id="gl-select-label">{DescriptionLabel}</MotifLabel>
          <TextField
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={description}
            onChange={(e) => setDescriptionInput(e.target.value)}
          />
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="button cancel-button">
            {cancelButtonLabel}
          </MotifButton>
          <MotifButton
            onClick={handleSubmit}
            className="button done-button"
          >
            {isEditMode ? updateButtonLabel : saveButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

GlModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  handleSourceValueClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  selectedRow: PropTypes.object,
};

export default GlModal;
