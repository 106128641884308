import React, { useState, useEffect } from "react";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import languageEN from "../../../../constants/languages/en/translations.json";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit, Delete } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box } from "@mui/material";
import SourceValue from "../../../../components/modals/dmaModals/SourcesValue";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchValueChainTypes,
  fetchValueChain,
  createNewValueChain,
  updateValueChain,
  deleteValueChain,
} from "../../../../features/slices/DMAValueChain";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useParams } from "react-router-dom";
import TopBar from "../Topbar";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import Pagination from "../../../../components/paginationForCards/Pagination";
import "./ValueChain.scss";

const ValueChain = () => {
  const [isValueChainTableView, setIsValueChainTableView] = useState(true);
  const [isValueChainModalOpen, setIsValueChainModalOpen] = useState(false);
  const [valueChainModalMode, setValueChainModalMode] = useState("add");
  const [valueChainModalTitle, setValueChainModalTitle] = useState("");
  const [currentValueChainPage, setCurrentValueChainPage] = useState(1);
  const [valueChainSearchQuery, setValueChainSearchQuery] = useState("");
  const [valueChainSelectedRow, setValueChainSelectedRow] = useState(null);
  const [valueChainTypes, setValueChainTypes] = useState([]);
  const [isValueChainDeleteModalOpen, setIsValueChainDeleteModalOpen] =
    useState(false);
  const [updateValueChainTrigger, setUpdateValueChainTrigger] = useState(0);

  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const { moduleId } = useParams();

  useEffect(() => {
    dispatch(fetchValueChain(moduleId));
    dispatch(fetchValueChainTypes()).then((response) => {
      if (response?.payload?.data) {
        setValueChainTypes(response.payload.data);
      }
    });
  }, [dispatch, moduleId, updateValueChainTrigger]);

  const {
    data: responseData,
    isLoading,
    isError,
  } = useSelector((state) => state.dmaValueChain);

  useEffect(() => {
    if (responseData?.data) {
      setValueChaindata(responseData.data);
    }
  }, [responseData]);

  const [valueChaindata, setValueChaindata] = useState([]);

  const handleValueChainEdit = (row) => {
    setValueChainSelectedRow(row);
    setValueChainModalMode("edit");
    setValueChainModalTitle("Edit value chain");
    setIsValueChainModalOpen(true);
  };

  const handleValueChainDeleteOpenModal = (row) => {
    setValueChainSelectedRow(row);
    setIsValueChainDeleteModalOpen(true);
  };

  const handleValueChainDeleteCloseModal = () => {
    setIsValueChainDeleteModalOpen(false);
  };

  const handleValueChainDelete = async () => {
    try {
      const response = await dispatch(
        deleteValueChain({ valueChainId: valueChainSelectedRow.id })
      )
        .unwrap()
        .then(() => {
          setUpdateValueChainTrigger((prev) => prev + 1);
          handleValueChainDeleteCloseModal();
        });
      if (!response.error) {
        setValueChaindata((prevData) => {
          if (Array.isArray(prevData)) {
            return prevData.filter(
              (valueChain) => valueChain.id !== valueChainSelectedRow.id
            );
          } else {
            return prevData;
          }
        });
        dispatch(fetchValueChain(moduleId));
      } else {
        console.error("Error deleting Value Chain:", response.error);
      }
    } catch (error) {
      console.error("Error deleting Value Chain:", error);
    }
  };

  const handleValueChainClick = async (valueChainData) => {
    try {
      let response;
      if (valueChainModalMode === "add") {
        response = await dispatch(
          createNewValueChain({ moduleId, valueChainData })
        );
        if (response.payload) {
          setValueChaindata((prevData) => [...prevData, response.payload]);
          setUpdateValueChainTrigger((prev) => prev + 1);
          handleValueChainCloseModal();
          dispatch(fetchValueChain(moduleId));
        } else {
          console.error("Error creating Value Chain:", response.error);
        }
      } else if (valueChainModalMode === "edit" && valueChainSelectedRow) {
        response = await dispatch(
          updateValueChain({
            valueChainId: valueChainSelectedRow.id,
            valueChainData,
          })
        );
        if (response.payload) {
          setValueChaindata((prevData) =>
            Array.isArray(prevData)
              ? prevData.map((valueChain) =>
                  valueChain.id === response.payload.id
                    ? response.payload
                    : valueChain
                )
              : [response.payload]
          );
          setUpdateValueChainTrigger((prev) => prev + 1);
          handleValueChainCloseModal();
          dispatch(fetchValueChain(moduleId));
        } else {
          console.error("Error updating Value Chain:", response.error);
        }
      }
      handleValueChainCloseModal();
      dispatch(fetchValueChain(moduleId));
    } catch (error) {
      console.error("Error creating/updating Value Chain:", error);
    }
  };

  const renderValueChainTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell
          key="actions"
          style={{ textAlign: "right", paddingRight: "2.55%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleValueChainEdit(row)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleValueChainDeleteOpenModal(row)}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    if (colName === "valueChainType.name") {
      return <TableCell key={index}>{row.valueChainType.name}</TableCell>;
    }
    return (
      <TableCell key={index} className="truncate">
        {value}
      </TableCell>
    );
  };

  const generateValueChainRowKey = (row) => row.id;

  const handleValueChainOpenModal = (mode) => {
    setValueChainModalMode(mode);
    if (mode === "add") {
      setValueChainModalTitle("New value chain");
    } else if (mode === "edit") {
      setValueChainModalTitle("Edit value chain");
    }
    setIsValueChainModalOpen(true);
  };

  const handleValueChainCloseModal = () => {
    setIsValueChainModalOpen(false);
  };

  const handleValueChainPageChange = (page) => {
    setCurrentValueChainPage(page);
  };

  const handleValueChainSearch = (event) => {
    setValueChainSearchQuery(String(event));
  };

  const columnsValueChain = [
    { colName: "name", label: languageEN.Name, showSorting: true },
    { colName: "valueChainType.name", label: languageEN.Type, showSorting: true },
    {
      colName: "description",
      label: languageEN.Description,
      showSorting: true,
    },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  if (isError) {
    return <div>Error loading data.</div>;
  }

  const getValueChainFilteredData = () => {
    return Array.isArray(valueChaindata)
      ? valueChaindata.filter((item) =>
          item.name?.toLowerCase().includes(valueChainSearchQuery.toLowerCase())
        )
      : [];
  };

  const paginatedData = getValueChainFilteredData().slice(
    (currentValueChainPage - 1) * itemsPerPage,
    currentValueChainPage * itemsPerPage
  );

  const totalPages = Math.ceil(
    getValueChainFilteredData().length / itemsPerPage
  );

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleValueChainOpenModal("add")}
        onChangeSearchInput={handleValueChainSearch}
      />
      <TopBar
        isTableView={isValueChainTableView}
        totalResults={valueChaindata.length}
        filteredResults={getValueChainFilteredData().length}
        onTableViewClick={() => setIsValueChainTableView(true)}
        onCardViewClick={() => setIsValueChainTableView(false)}
      />
      <div className="value-chain-card-table-switch">
        {isLoading && (
          <MotifProgressLoader
            data-testid="loading-spinner"
            show
            variant="default"
          />
        )}
        {isValueChainTableView ? (
          <ClientTable
            columns={columnsValueChain}
            data={getValueChainFilteredData()}
            itemsPerPage={5}
            renderTableCell={(colName, value, row, index) => {
              return renderValueChainTableCell(colName, value, row, index);
            }}
            generateRowKey={generateValueChainRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                pageName="ValueChain"
                stakeHolderType={item.valueChainType.name}
                descriptionHeaderText={item.description}
                handleEdit={() => handleValueChainEdit(item)}
                handleDelete={() => handleValueChainDeleteOpenModal(item)}
              />
            ))}
          </div>
        )}
        {!isValueChainTableView && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentValueChainPage}
            onPageChange={handleValueChainPageChange}
          />
        )}
      </div>
      {isValueChainModalOpen && (
        <SourceValue
          onClose={handleValueChainCloseModal}
          setName={valueChainModalTitle}
          handleStatusChange={() => {}}
          handleSourceValueClick={handleValueChainClick}
          modalMode={valueChainModalMode}
          NameLabel={languageEN.Name}
          TypeLabel={languageEN.Type}
          statusActiveLabel={languageEN.Upstream}
          statusInactiveLabel={languageEN.DownStream}
          DescriptionLabel={languageEN.Description}
          cancelButtonLabel={languageEN.Cancel}
          saveButtonLabel={languageEN.AddToList}
          updateButtonLabel={languageEN.Update}
          selectedRow={valueChainSelectedRow}
          pageName="Value Chain"
          types={valueChainTypes}
        />
      )}

      {isLoading && <MotifProgressLoader show variant="default" />}
      {isValueChainDeleteModalOpen && (
        <DeleteModal
          isOpen={isValueChainDeleteModalOpen}
          onClose={() => setIsValueChainDeleteModalOpen(false)}
          setName={languageEN.DeleteLabel}
          labelText={languageEN.DeletConfirmationSources}
          onCancel={() => setIsValueChainDeleteModalOpen(false)}
          handleDeleteClick={handleValueChainDelete}
          cancelButtonLabel={languageEN.Cancel}
          deleteButtonLabel={languageEN.DeleteLabel}
        />
      )}
    </div>
  );
};

export default ValueChain;
