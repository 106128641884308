import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_UTC_COUNT } from "../../services/Api";

export const fetchUnderstandingTheContextCount = createAsyncThunk(
  "fetchUnderstandingTheContextCount",
  async (moduleId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_UTC_COUNT}/${moduleId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching Understanding The Context Count:", error);
      return rejectWithValue(error.response?.data || "Error fetching Understanding The Context Count");
    }
  }
);
const dmaOverviewSlice = createSlice({
  name: "dmaOverview",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnderstandingTheContextCount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUnderstandingTheContextCount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUnderstandingTheContextCount.rejected, (state, action) => {
      console.error("error", action.payload);
      state.isError = true;
    });
  },
});

export default dmaOverviewSlice.reducer;