import React from "react";
import {
  MotifHeader,
  MotifButton,
  MotifHeaderLogo,
  MotifIcon,
} from "@ey-xd/motif-react";
import language from "../../constants/languages/en/translations.json";
import {
  PrivacyNote,
  HelpDesk,
  UserProfile,
  Maintenance,
  Location,
} from "../../constants/icons/Icons";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import notice from "../../assets/files/privacyNotice.pdf";
import PropTypes from "prop-types";

const Header = ({ isAdmin }) => {
  const navigate = useNavigate();
  const handleMaintenance = () => {
    navigate("/maintenance");
  };
  return (
    <MotifHeader
      appHeaderName={language.Title}
      logo={
        <MotifHeaderLogo>
          <a href="/">
            {/* <MotifIcon
                 aria-hidden="true"
                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNTYxcHgiIGhlaWdodD0iNTc0cHgiIHZpZXdCb3g9IjAgMCA1NjEgNTc0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2My4xICg5MjQ1MikgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+RVlfTG9nb19CZWFtX1doaXRlX1llbGxvd19DX0NNWUs8L3RpdGxlPgogICAgPGRlZnM+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIwLjA2IDAuOTc0IDU2MC4zOTQgMC45NzQgNTYwLjM5NCA1NzQgMC4wNiA1NzQiPjwvcG9seWdvbj4KICAgIDwvZGVmcz4KICAgIDxnIGlkPSJFWV9Mb2dvX0JlYW1fV2hpdGVfWWVsbG93X0NfQ01ZSyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlsaW5lIGlkPSJGaWxsLTEiIGZpbGw9IiNGRkZGRkUiIHBvaW50cz0iODkuOTE2IDQ2Mi4wMzEgMTkyLjQxNyA0NjIuMDMxIDE5Mi40MTcgNDAyLjUxMSA4OS45MTYgNDAyLjUxMSA4OS45MTYgMzU1LjczNCAyMDMuMzA0IDM1NS43MzQgMTY1LjY4NSAyOTAuNTM3IDQuOTAzIDI5MC41MzcgNC45MDMgNTc0IDIzMS42NjcgNTc0IDIzMS42NjcgNTA4LjgwMyA4OS45MTYgNTA4LjgwMyA4OS45MTYgNDYyLjAzMSI+PC9wb2x5bGluZT4KICAgICAgICA8ZyBpZD0iR3JvdXAtNSI+CiAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4KICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+CiAgICAgICAgICAgIDwvbWFzaz4KICAgICAgICAgICAgPGcgaWQ9IkNsaXAtMyI+PC9nPgogICAgICAgICAgICA8cG9seWxpbmUgaWQ9IkZpbGwtMiIgZmlsbD0iI0ZGRkZGRSIgbWFzaz0idXJsKCNtYXNrLTIpIiBwb2ludHM9IjM4Mi41ODkgMjkwLjUzNyAzMzQuNDM5IDM4My4wNDIgMjg2LjQwNSAyOTAuNTM3IDE5Mi40MTEgMjkwLjUzNyAyOTEuNDUyIDQ2Mi4wMzEgMjkxLjQ1MiA1NzQgMzc2LjIwNiA1NzQgMzc2LjIwNiA0NjIuMDMxIDQ3NS4zNzggMjkwLjUzNyAzODIuNTg5IDI5MC41MzciPjwvcG9seWxpbmU+CiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iRmlsbC00IiBmaWxsPSIjRkJEMDIwIiBtYXNrPSJ1cmwoI21hc2stMikiIHBvaW50cz0iNTYwLjM5NCAwLjk3NCAwIDIwNS41MDUgNTYwLjM5NCAxMDYuNTExIDU2MC4zOTQgMC45NzQiPjwvcG9seWxpbmU+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
            /> */}
          </a>
        </MotifHeaderLogo>
      }
      iconButton={<MotifIcon title="Icon Button" />}
      iconsContainer={
        <div className="icons-container">
          <MotifButton
            className="motif-body"
            onClick={() => window.open(notice, "_blank")}
          >
            <PrivacyNote />
            {language.PrivacyNote}
          </MotifButton>

          <MotifButton className="motif-body">
            <HelpDesk />
            {language.HelpDesk}
          </MotifButton>

          <MotifButton className="motif-body" onClick={handleMaintenance}>
            <Maintenance />
            {language.Maintenance}
          </MotifButton>

          <MotifButton className="motif-body">
            <Location />
            USA
          </MotifButton>
          <UserProfile />
        </div>
      }
    />
  );
};

Header.propTypes = {
  isAdmin: PropTypes.bool,
};

export default Header;
