
const API_BASE_URL = process.env.API_BASE_URL || "https://esgsuiteapi.azurewebsites.net/api/";

// Project URLs
export const GET_ALL_PROJECTS = `${API_BASE_URL}Project/GetAll`;
export const GET_PROJECT_DETAILS_URL = `${API_BASE_URL}Project/GetProjectDetailsById`;
export const UPDATE_PROJECT_DETAILS_URL = `${API_BASE_URL}Project/UpdateProjectDetailsById`;
export const PROJECT_BASE_URL = `${API_BASE_URL}Project/`;
export const GET_USERS_URL = `/GetUsers`;
export const CREATE_NEW_USER_URL = `/AddUser`;
export const CREATE_SESSION_URL = `${API_BASE_URL}Project/CreateSession`;
export const CREATE_MODULE_URL = `${API_BASE_URL}Project/CreateModule`;
export const GET_ALL_USERS_URL = `${API_BASE_URL}Project/GetAllUsers`;
export const UPDATE_USER_URL = `${API_BASE_URL}Project/UpdateProjectRoleName`;
export const DELETE_USER_URL = `/DeleteProjectUser`;
export const RETIRE_PROJECT_URL = `${API_BASE_URL}Project/RetireProject`;

// Maintenance URLs
export const GET_ALL_Status_URL = `${API_BASE_URL}Maintenance/GetAllStatus`;
export const GET_MAINTENANCE_STATUS_URL = `${API_BASE_URL}Maintenance/UpdateStatus`;

// DMA APS URLs
export const GET_ALL_APS_TYPES_URL = `${API_BASE_URL}DMA/GetAllAPSTypes`;
export const GET_ALL_APS_URL = `${API_BASE_URL}DMA/GetAllAPS/`;
export const CREATE_APS_URL = `${API_BASE_URL}DMA/CreateAPS`;
export const UPDATE_APS_URL = `${API_BASE_URL}DMA/UpdateAPS`;
export const DELETE_APS_URL = `${API_BASE_URL}DMA/DeleteAPS`;

// DMA Sources URLs
export const GET_ALL_SOURCES_TYPES_URL = `${API_BASE_URL}DMA/GetSourceTypes`;
export const GET_ALL_SOURCES_URL = `${API_BASE_URL}DMA/GetAllSources`;
export const CREATE_SOURCES_URL = `${API_BASE_URL}DMA/CreateSource`;
export const UPDATE_SOURCES_URL = `${API_BASE_URL}DMA/UpdateSource`;
export const DELETE_SOURCES_URL = `${API_BASE_URL}DMA/DeleteSource`;

// DMA Geographical Location URLs
export const GET_ALL_GL_URL = `${API_BASE_URL}DMA/GetAllGeographicalLocation`;
export const CREATE_GL_URL = `${API_BASE_URL}DMA/CreateGeographicalLocation`;
export const EDIT_GL_URL = `${API_BASE_URL}DMA/UpdateGeographicalLocation`;
export const DELETE_GL_URL = `${API_BASE_URL}DMA/DeleteGeographicalLocation`;

// DMA Stakeholder URLs
export const GET_ALL_SH_URL = `${API_BASE_URL}DMA/GetAllStakeholders/`;
export const CREATE_SH_URL = `${API_BASE_URL}DMA/CreateStakeholder/`;
export const UPDATE_SH_URL = `${API_BASE_URL}DMA/UpdateStakeholder/`;
export const GET_SH_TYPES_URL = `${API_BASE_URL}DMA/GetStakeholderTypes`;
export const GET_SH_IE_TYPES_URL = `${API_BASE_URL}DMA/GetStakeholderIntExtTypes`;
export const DELETE_SH = `${API_BASE_URL}DMA/DeleteStakeholder`;

// DMA Value Chain URLs
export const GET_VALUE_CHAIN_TYPES = `${API_BASE_URL}DMA/GetValueChainTypes`;
export const CREATE_VALUE_CHAIN = `${API_BASE_URL}DMA/CreateValueChain`;
export const UPDATE_VALUE_CHAIN = `${API_BASE_URL}DMA/UpdateValueChain`;
export const DELETE_VALUE_CHAIN = `${API_BASE_URL}DMA/DeleteValueChain`;
export const GET_VALUE_CHAIN = `${API_BASE_URL}DMA/GetAllValueChain`;

// DMA Sustainability Matters URLs
export const GET_ALL_SUSTAINABILITY_MATTERS = `${API_BASE_URL}DMA/GetAllModuleSustainabilityMatter`;
export const CREATE_SUSTAINABILITY_MATTERS = `${API_BASE_URL}DMA/CreateSustainabilityMatter`;
export const UPDATE_SUSTAINABILITY_MATTERS = `${API_BASE_URL}DMA/UpdateSustainabilityMatter`;
export const DELETE_SUSTAINABILITY_MATTERS = `${API_BASE_URL}DMA/DeleteSustainabilityMatter`;

// DMA ESRS URLs
export const GET_ESRS_URL = `${API_BASE_URL}DMA/GetFrameworkReferences`;
export const GET_ESRS_AR16_URL = `${API_BASE_URL}DMA/GetESRSAR16`;
export const GET_PARENTS_URL = `${API_BASE_URL}DMA/GetParents`;

// Get Overview Screen URLs
export const GET_UTC_COUNT = `${API_BASE_URL}DMA/GetCount`;
export const CREATE_UPDATE_THRESHOLD = `${API_BASE_URL}DMA/UpsertThreshold`;
export const GET_THRESHOLD = `${API_BASE_URL}DMA/GetThreshold`;
