import React, { useState } from "react";
import "./SessionAccordion.scss";
import { View, Edit, Delete } from "../../../constants/icons/Icons";
import {
  MotifAccordion,
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";

function Accordion({ data, isAdmin, handleModuleModal, ModuleTitleEN }) {
  return (
    <div>
      {data.map((item) => (
        <AccordionItem
          key={item.id}
          title={item.sessionName}
          content={item.modules}
          isAdmin={isAdmin}
          handleModuleModal={() => handleModuleModal(item.sessionName, item.id)}
          ModuleTitleEN={ModuleTitleEN}
        />
      ))}
    </div>
  );
}

function AccordionItem({
  title,
  content,
  isAdmin,
  onClose,
  handleModuleModal,
  ModuleTitleEN,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-item" data-testid="accordion-item">
      &nbsp;
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
        }}
      >
        <MotifAccordion
          onClose={onClose}
          onOpen={function Ga() {
            setIsOpen(!isOpen);
          }}
          style={{
            flex: "1",
          }}
          variant="editorial"
        >
          <MotifAccordionTrigger>
            <div className="trigger-content">
              {title}
              {isAdmin && (
                <div className="module">
                  <a
                    className="module-link"
                    onClick={handleModuleModal}
                    href={handleModuleModal}
                  >
                    {ModuleTitleEN} &nbsp;&nbsp;
                  </a>
                </div>
              )}
            </div>
          </MotifAccordionTrigger>
          {isOpen && (
            <MotifAccordionContent>
              <table>
                {content.map((item) => (
                  <tr>
                    &nbsp;
                    <td className="session-table-data" key={item.id}>
                      {item.moduleName}
                      <div className="session-accordion-icons">
                        <IconButton>
                          <div className="icon-container-session">
                            {<View />}&nbsp;&nbsp;{<Delete />}
                          </div>
                        </IconButton>
                      </div>
                      &nbsp;&nbsp;
                    </td>
                    &nbsp;
                  </tr>
                ))}
                &nbsp; &nbsp;
              </table>
            </MotifAccordionContent>
          )}
        </MotifAccordion>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  isAdmin: PropTypes.bool,
  data: PropTypes.array,
  handleModuleModal: PropTypes.func,
  ModuleTitleEN: PropTypes.string,
};

AccordionItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  isAdmin: PropTypes.bool,
  onClose: PropTypes.bool,
  handleModuleModal: PropTypes.func,
  ModuleTitleEN: PropTypes.string,
};
export default Accordion;
