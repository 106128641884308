import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_SOURCES_TYPES_URL,
  GET_ALL_SOURCES_URL,
  CREATE_SOURCES_URL,
  UPDATE_SOURCES_URL,
  DELETE_SOURCES_URL,
} from "../../services/Api";

// Fetch all Sources data
export const fetchSources = createAsyncThunk(
  "dmaSources/fetchSources",
  async (moduleId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${GET_ALL_SOURCES_URL}/${moduleId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching APS data:", error);
      return rejectWithValue(
        error.response?.data || "Error fetching Sources data"
      );
    }
  }
);

// Create a new Sources
export const createSources = createAsyncThunk(
  "dmaSources/createSources",
  async ({ moduleId, sourceData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${CREATE_SOURCES_URL}/${moduleId}`,
        sourceData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating APS:", error);
      return rejectWithValue(error.response?.data || "Error creating Sources");
    }
  }
);

// Update Sources
export const updateSources = createAsyncThunk(
  "dmaSources/updateSources",
  async ({ sourceId, sourceData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${UPDATE_SOURCES_URL}/${sourceId}`,
        sourceData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating APS:", error);
      return rejectWithValue(error.response?.data || "Error updating Sources");
    }
  }
);

// Delete Sources
export const deleteSources = createAsyncThunk(
  "dmaSources/deleteSources",
  async ({ sourceId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${DELETE_SOURCES_URL}/${sourceId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting APS:", error);
      return rejectWithValue(error.response?.data || "Error deleting APS");
    }
  }
);

// Fetch Sources types
export const fetchSourcesTypes = createAsyncThunk(
  "dmaSources/fetchSourcesTypes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_SOURCES_TYPES_URL);
      return response.data;
    } catch (error) {
      console.error("Error fetching Sources types:", error);
      return rejectWithValue(
        error.response?.data || "Error fetching Sources types"
      );
    }
  }
);

const dmaSourcesSlice = createSlice({
  name: "dmaSources",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
    types: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSources.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchSources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(fetchSourcesTypes.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchSourcesTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.types = action.payload;
      })
      .addCase(fetchSourcesTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(createSources.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(createSources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(updateSources.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(updateSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateSources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      })
      .addCase(deleteSources.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteSources.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(deleteSources.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("error", action.payload);
      });
  },
});

export default dmaSourcesSlice.reducer;
