import React from "react";
import {
  MotifFormField,
  MotifOption,
  MotifInput,
  MotifLabel,
  MotifMessage,
  MotifSelect,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "./NewProjectForm.scss";

const NewProjectForm = ({ formFields, formState, setFormState }) => {
  // Handler for input change
  const handleInputChange = (id, value) => {
    setFormState({ ...formState, [id]: value });
  };

  // Render form fields based on the type
  const renderFormField = (field) => {
    switch (field.type) {
      case "input":
        return (
          <MotifInput
            className={`new-project-input ${field.id}`}
            value={formState[field.id]}
            data-testId={field.id}
            onChange={(e) => handleInputChange(field.id, e.target.value)}
            hideClearButton={true}
          />
        );
      case "select":
        return (
          <MotifSelect
            className="new-project-input"
            value={formState[field.id]}
            filter={field.filter}
            multiple={field.multiple}
            onChange={(e) => handleInputChange(field.id, e)}
            hideClearButton={true}
          >
            {field.options.map((option) => (
              <MotifOption key={option.value} value={option.value}>
                {option.label}
              </MotifOption>
            ))}
          </MotifSelect>
        );
      default:
        return null;
    }
  };

  return (
    <div className="form-row">
      {formFields.map((field) => (
        <div key={field.id} className={`form-group ${field.class}`}>
          <MotifMessage id={`${field.id}-message`}>{field.label}</MotifMessage>
          <MotifFormField>
            <MotifLabel htmlFor={`${field.id}-input`}>
              {field.placeholder}
            </MotifLabel>
            {renderFormField(field)}
          </MotifFormField>
        </div>
      ))}
    </div>
  );
};
NewProjectForm.propTypes = {
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["input", "select"]).isRequired,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      class: PropTypes.string,
      filter: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
};

export default NewProjectForm;
