import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_ALL_Status_URL,
  GET_MAINTENANCE_STATUS_URL,
} from "../../services/Api";

export const getAllStatus = createAsyncThunk(
  "getAllStatus",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_Status_URL);
      return response.data;
    } catch (error) {
      console.error("Error fetching all status:", error);
      return rejectWithValue(
        error.response?.data || "Error fetching all status"
      );
    }
  }
);

export const getMaintainenceStatus = createAsyncThunk(
  "getMaintainenceStatus",
  async (
    { isActive, messageWhenInactive, lastModifiedBy },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(GET_MAINTENANCE_STATUS_URL, {
        status: isActive,
        messageWhenInactive,
        lastModifiedBy,
      });
      return response.data;
    } catch (error) {
      console.error("Error updating status:", error);
      return rejectWithValue(error.response?.data || "Error updating status");
    }
  }
);

const getAllStatusSlice = createSlice({
  name: "allStatus",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    statusUpdate: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getAllStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      console.error("error", action.payload);
    });
    builder.addCase(getMaintainenceStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getMaintainenceStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.statusUpdate = true;
    });
    builder.addCase(getMaintainenceStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      console.error("error", action.payload);
    });
  },
});

export default getAllStatusSlice.reducer;
