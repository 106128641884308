import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavLeft, EditSvg, Dot } from "../../constants/icons/Icons";
import Header from "../../components/headers/Header";
import Card from "../../components/card/Card";
import "./InstanceMaintenance.scss";
import ChangeStatusModal from "./ChangeStatusModal";
import {
  MotifButton,
  MotifBreadcrumb,
  MotifBreadcrumbItem,
  MotifProgressLoader,
} from "@ey-xd/motif-react";
import maintenanceLanguage from "../../constants/languages/en/translations.json";
import InstanceHome from "../instanceHome/InstanceHome";
import {
  getAllStatus,
  getMaintainenceStatus,
} from "../../features/slices/InstanceMaintenance";

const InstanceMaintenance = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [allStatusData, setAllStatusData] = useState([]);
  const [status, setStatus] = useState(""); 
  const [instanceName, setInstanceName] = useState("");

  const handleNavLeft = () => {
    navigate("/");
  };

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStatus());
  }, [dispatch]);

  const { data: responseData, isLoading } = useSelector(
    (state) => state.allStatus || { data: null, isLoading: false }
  );

  useEffect(() => {
    const labelMappingApiResponse = {
      totalProjects: maintenanceLanguage.totalProjects,
      activeProjects: maintenanceLanguage.activeProjects,
      retiredProjects: maintenanceLanguage.retiredProjects,
      totalUsers: maintenanceLanguage.totalUsers,
      totalSessions: maintenanceLanguage.totalSessions,
      totalDMAModules: maintenanceLanguage.totalDMAModules,
      totalDDModules: maintenanceLanguage.totalDDModules,
      totalHLModules: maintenanceLanguage.totalHLModules,
    };
    if (responseData) {
      const instanceData = responseData.data;
      setStatus(instanceData.status);
      setInstanceName(instanceData.instanceName);
      const transformedData = Object.entries(instanceData)
        .filter(([key]) => key !== "instanceName" && key !== "status")
        .map(([key, value]) => {
          const newLabel = labelMappingApiResponse[key] || key;
          return {
            label: newLabel,
            value: value,
          };
        });
      setAllStatusData(transformedData);
    }
  }, [responseData]);
  const handleStatusUpdate = (selectedStatus, message) => {
    const isActive = selectedStatus === maintenanceLanguage.StatusActiveEN;
    const updatePayload = {
      isActive,
      messageWhenInactive: !isActive ? message : "",
      lastModifiedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    };

    dispatch(getMaintainenceStatus(updatePayload))
      .unwrap()
      .then(() => {
        handleClose();
        dispatch(getAllStatus());
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  return (
    <div>
      <Header />
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          show
          variant="default"
        />
      )}
      {isOpen && (
        <ChangeStatusModal
          onClose={handleClose}
          setInstanceName={instanceName}
          handleStatusUpdate={handleStatusUpdate}
          currentStatus={status} 
        />
      )}
      <div className="maintenance-container">
        <div>
          <MotifBreadcrumb
            triggerButtonProps={{
              "aria-label": "Custom Trigger Button aria-label",
              title: "Custom Trigger Button title",
            }}
          >
            <MotifBreadcrumbItem>
              <a href="/#">
                <strong>All Projects</strong>
              </a>
            </MotifBreadcrumbItem>
            <MotifBreadcrumbItem>
              <a href="/#">
                <strong>USA</strong>
              </a>
            </MotifBreadcrumbItem>
            <MotifBreadcrumbItem>
              <a href="/#">Settings</a>
            </MotifBreadcrumbItem>
          </MotifBreadcrumb>
        </div>
        <div className="header-container">
          <div className="maintenance-section1">
            <div
              data-testid="navButton"
              className="nav-left-btn"
              onClick={handleNavLeft}
            >
              <NavLeft />
            </div>
            <p
              data-testid="maintenance-subheader"
              className="motif-h5-default-bold"
            >
              {maintenanceLanguage.maintenanceSubHeader}
            </p>
          </div>
          <div className="maintenance-section2">
            <MotifButton
              className="active-btn"
              size="small"
              type="submit"
              variant="primary-alt"
            >
              <Dot />
              &nbsp; {status}
            </MotifButton>
            <MotifButton
              className="subheader-button-add"
              size="small"
              type="submit"
              variant="primary-alt"
              onClick={handleModalOpen}
            >
              {maintenanceLanguage.maintenanceChangeStatus} &nbsp;
              <EditSvg />
            </MotifButton>
          </div>
        </div>
        <div className="cards-container">
          {allStatusData.map(({ label, value }) => (
            <Card key={label} label={label} value={value} />
          ))}
        </div>
      </div>
      <InstanceHome showHeader={false} showButton={false} />
    </div>
  );
};

export default InstanceMaintenance;
