import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_ALL_USERS_URL } from "../../services/Api";

export const fetchAllUsers = createAsyncThunk(
  "getAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_ALL_USERS_URL);
      return response.data;
    } catch (error) {
      console.error("Error fetching projects:", error);
      return rejectWithValue(error.response?.data || "Error fetching projects");
    }
  }
);
const fetchAllUsersSlice = createSlice({
  name: "allUsers",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAllUsers.rejected, (state, action) => {
      console.error("error", action.payload);
      state.isError = true;
    });
  },
});

export default fetchAllUsersSlice.reducer;
